import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import Webcam from "react-webcam";
import { connect } from 'react-redux';

import * as service from './../../../../Service/service';

class SelfieModal extends Component {

    state = {
        isCaptured: false,
        image: '',
        file: '',
        isUploaded: false,
        uploadData: {}
    }


    dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    uploadSelfie = () => {
        const formData = new FormData();
        formData.append("file", this.state.file);
        formData.append("documentType", "SELFIE");

        service.uploadDocument(formData).then((response) => {
            this.setState({
                ...this.state,
                isUploaded: true,
                uploadData: response.data
            })
        }, (error) => {
            console.log(error);
        });
    }

    onBackdrop = () => {
        this.props.hideWebcamModal();
    }

    webcamRef = '';

    constructor() {
        super();
        this.webcamRef = React.createRef(null);
    }

    reTry = () => {
        this.setState({
            ...this.state,
            isCaptured: false,
            image: ''
        });
    }

    caputure = () => {
        const imageSrc = this.webcamRef.current.getScreenshot();
        var file = this.dataURLtoFile(imageSrc, 'selfie.jpeg');
        this.setState({
            ...this.state,
            isCaptured: true,
            image: imageSrc,
            file: file
        })
    }

    getWebCam = () => {

        const videoConstraints = {
            width: 1280,
            height: 720,
            facingMode: "user"
        };

        return this.state.isCaptured ? ( ! this.state.isUploaded && <img src={this.state.image} alt="captured" />)
            : this.props.kyc.showWebcamModal ? (<Webcam screenshotQuality={1} audio={false} ref={this.webcamRef} screenshotFormat="image/jpeg" width={'100%'} videoConstraints={videoConstraints} />) : '';

    }

    render() {

        return (
            <Modal visible={this.props.kyc.showWebcamModal} onClickBackdrop={this.onBackdrop}>
                <div className="modal-header">
                    <h5 className="modal-title">Take Selfie</h5>
                </div>
                <div className="modal-body pt-0 pb-0" style={{ height: 'inherit' }}>
                    <div className="row" style={{ minHeight: '250px' }}>

                        {
                            this.state.isUploaded && this.state.isCaptured && (
                                <div className="col-12 m-auto">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h5>Thank you for uploading Selfie.</h5>
                                            <span className="text-black-50">*It may take some time to verify selfie.</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            this.getWebCam()
                        }
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" style={{ display: this.state.isUploaded ? 'none' : 'block' }} onClick={this.reTry} className="btn btn-light btn-sm" >Dismiss</button>
                    <button type="button" style={{ display: this.state.isUploaded ? 'none' : 'block' }} onClick={this.caputure} className="btn btn-secondary btn-sm" >Capture</button>
                    <button type="button" style={{ display: this.state.isUploaded ? 'none' : 'block' }} onClick={this.uploadSelfie} className="btn btn-success btn-sm" >Upload</button>
                    <button type="button" onClick={this.onBackdrop} className="btn btn-danger btn-sm" >Close</button>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (currentState) => {
    return {
        kyc: currentState.kyc,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        hideWebcamModal: () => {
            dispatch({ type: 'kyc.webcam.hide' });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(SelfieModal);