import React, { Component } from 'react';

class Blog5 extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="col-12">
                    <span>In today’s stressful world your room is the only place where you can relax,recharge and express yourself, creating a space that can resonate with your personality, where you can feel less stressed with an aesthetic look. With some creativity and some do-it-yourself(DIY) you can transform your room into an aesthetic one. Here are the 5 DIY ideas which can definitely help you to make your room more aesthetic.</span><br /><br></br>
                    <h4>Walls:</h4><div>Starting with the most important part of your room i.e. choosing right colours for your walls. To make your room look aesthetic always go for light basic shades like white, grey, Beige, Sky blue, Pink, etc. Light colour will make your room more bright and peaceful. To make walls of your room more fascinating you can also add some wallpapers on your walls. Blank wall gives you an opportunity to showcase your creativity by drawing some good arts on your wall that will enahce the vibe of your room. Adding some frames to your wall can make the room look more aesthetic.</div><br />
                    <center><img
                        style={{ maxHeight: "700px", maxWidth: "400px" }}
                        className="d-block w-100 lazyload "
                        src="https://voorent.com/voorent-storage/Blog5.1.jpg"
                        >
                    </img></center><br></br>
                    <h4>Furniture:</h4><div>The room full of furniture will always look very unattractive, to make a room look fascinating the basic furnitures (Bed, side tables and wardrobe) are more than enough, this will make your room look more spacious and attractive too. If you are living in a rented flat and can’t afford to purchase furniture but wants to complete your room then voorent is there for you. Voorent is the best funiture renting company which brings you the one stop solution for your problem by renting furniture in Delhi and gurgaon at a very low prices and  with free delivery within 2-3 days of ordering.</div><br />
                    <center><img
                        style={{ maxHeight: "700px", maxWidth: "400px" }}
                        className="d-block w-100 lazyload "
                        src="https://voorent.com/voorent-storage/Blog5.2.jpg"
                        >
                    </img></center><br></br>
                    <center><h4>Lightning:</h4><div>Coming on the most important part of the room without which your room is incomplete i.e good lightning, lightning sets the mood and ambience of the room. Experimenting with the lightning fixture can be an option for cozy and aesthetic vibe of your room.<br></br>
                        <ul>
                            <li><b>Fairy lights:</b> string lights aren’t just for the festive season, you can spread these lights over bed,walls,or over a mirror to create that fascinating look of your room.</li>
                            <li><b>DIY light Jar:</b> you can also use unused jar and fill string lights inside it and place that jar in your room or hang them from ceiling.</li>
                        </ul>
                    </div><br />
                    <center><img
                        style={{ maxHeight: "700px", maxWidth: "400px" }}
                        className="d-block w-100 lazyload "
                        src="https://voorent.com/voorent-storage/Blog5.3.jpg"
                        >
                    </img></center><br></br>
                    <h4>Adding Greens:</h4><div>By adding green indoor plants in your room can bring the positive vibe which can definetly helps you out in relaxing with some positive vibe around your room. You can also make some DIY hanging plant pots to enhance the look of your room with maintaing that positive vibe.</div><br />
                    <center></center><img
                        style={{ maxHeight: "700px", maxWidth: "400px" }}
                        className="d-block w-100 lazyload "
                        src="https://voorent.com/voorent-storage/Blog5.4.jpg"
                        >
                    </img></center><br></br>
                    <h4>Personalized details:</h4><div>It’s the small details which can bring a significant change.<br></br>
                        <ul>
                            <li><b>Colour of the furniture:</b> To enahance the look of your make sure the furniture you are having matches with the theme of the room, a room with many colours looks very unattractive.</li>
                            <li><b>Bedsheets and pillow cover:</b> you can sew or paint designs onto the plain pillow cover to add that personalized touch to your bedding.</li>
                        </ul>
                    </div><br />
                    <span>Above are some ideas which you can use to make your room look more aesthetic.</span><br></br>
                    <span>For more informative blogs visit: <a href="https://voorent.com"><u>https://voorent.com</u></a></span><br></br>
                </div>
            </React.Fragment>
        );
    }
}

export default Blog5;