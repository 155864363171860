import React, { Component } from 'react';

class KycElement extends Component {

    statusColor=(verifyStatus)=>{
        if(verifyStatus=='VERIFIED'){
            return "text-success font-weight-bold";
        }
        else if(verifyStatus == 'PENDING'){
            return "text-warning font-weight-bold";
        }
        else{
            return "text-danger font-weight-bold"
        }
    }

    render() {
        return (
            <div className="col-12 p-3">
                <div className="row">
                    <div className="col-8 col-md-10">
                        <span className="text-uppercase d-block font-weight-bold">{this.props.kycItem.documentType}</span>
                        <span >Status : </span>
                        <span className={this.statusColor(this.props.kycItem.verificationStatus)}> {this.props.kycItem.verificationStatus} </span>
                        <span className="d-block" >Uploaded On : {new Date(this.props.kycItem.uploadedOn).toDateString()}</span>
                        <a target="_blank" className="d-block text-primary" href={this.props.kycItem.documentUrl}> Download Image</a>
                    </div>
                    <div className="col-4 col-md-2 text-right">
                        <button onClick={(e) => this.props.deleteDocument(this.props.kycItem.documentId)} className="btn btn-danger btn-sm btn-block"> Delete </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default KycElement;