import React, { Component } from 'react';
import CustomerDetails from '../User/Cart/CustomerDetails/CustomerDetails';
import H2Title from '../Generic/Element/Heading';
import HeaderComponent from '../Generic/Header/HeaderComponent';
import BreadCrumbs from '../Generic/BreadCrumbs/BreadCrumbs';
import { connect } from 'react-redux';

class AddNewAddress extends Component {

    state = {

    }       

    componentDidMount(){
        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: './' }, { name: 'Address', link: '#' }],
            showSidebar: false,
            sidebar: []
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderComponent />
                <BreadCrumbs />
                <main role="main" className="container m-auto">
                <div className="row mt-5">
                            
                        <div className="col-5 col-md-5 d-none d-md-block mr-5" style={{ minHeight: '100%', backgroundPosition: '695px -409px', backgroundImage: 'url("https://images.unsplash.com/photo-1532103054090-3491f1a05d0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80")' }}>

                        </div>
                        <div className="col-4 col-md-5 d-none d-md-block position-relative ml-5" style={{ minHeight: '450px' }}>
                            <H2Title value="Add Address" />
                            <CustomerDetails hideSelectFromSaved='true'/>

                        </div>
                           
                        </div>
                    
                </main>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)  (AddNewAddress);