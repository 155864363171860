import React, { Component } from 'react';
import AccountHeader from './../Generic/Header/AccountHeader';
import BreadCrumbs from './../Generic/BreadCrumbs/BreadCrumbs';
import { connect } from 'react-redux';
import AddressComponent from './Address/AddressComponent';
import ChangePasswordComponent from './ChangePassword/ChangePasswordComponent';
import DepositComponent from './Deposit/DepositComponent';
import HomeComponent from './Home/HomeComponent';
import KycComponent from './Kyc/KycComponent';
import OrderComponent from './Order/OrderComponent';
import PaymentComponent from './Payment/PaymentComponent';
import InvoiceComponent from './Invoices/InvoiceComponent';
import IssuesComponent from './Issues/issuesComponent';
import EnquiryComponent from './Enquiry/EnquiryComponent';

class AccountWrapper extends Component {

    state = {
        currentComponent: 'Home'
    }

    onComponentChange = (comp) => {

        this.setState({
            ...this.state,
            currentComponent: comp
        });
    }

    componentDidMount() {
        if (typeof localStorage.keycloak == "undefined") {
            window.location.href = "./";
        }

        if(window.location.search.length>1){
            var urlParams = new URLSearchParams(window.location.search);
            if(urlParams.has('page')){
                
                if(urlParams.get('page') == 'invoice'){
                    this.setState({
                        ...this.state,
                        currentComponent: 'Invoices' 
                    }, () => {
                        this.renderComponent();
                    })
                }else if(urlParams.get('page') == 'order'){
                    this.setState({
                        ...this.state,
                        currentComponent: 'Orders' 
                    }, () => {
                        this.renderComponent();
                    })
                }
                
            }
        }
    }


    renderComponent = () => {

        this.props.updateBreadcumbs({
            navigation : [{ name: 'Dashboard', link: './account' }, { name: this.state.currentComponent, link: '#' }],
            showSidebar : false,
            sidebar : []
        });

        let renderMe = null;

        switch (this.state.currentComponent) {
            case 'Home': renderMe = (<HomeComponent />); break;
            case 'Orders': renderMe = (<OrderComponent />); break;
            case 'Deposits': renderMe = (<DepositComponent />); break;
            case 'Kyc': renderMe = (<KycComponent />); break;
            case 'Payments': renderMe = (<PaymentComponent />); break;
            case 'Addresses': renderMe = (<AddressComponent />); break;
            case 'Password': renderMe = (<ChangePasswordComponent />); break;
            case 'Invoices': renderMe = (<InvoiceComponent />); break;
            case 'Issues': renderMe = (<IssuesComponent />); break;
            case 'Enquiry': renderMe = (<EnquiryComponent />); break;            
            default: renderMe = (<HomeComponent />);
        }

        return renderMe;
    }

    render() {
        return (
            <React.Fragment>
                <AccountHeader handleMenuClick={this.onComponentChange} />

                <BreadCrumbs />

                <main role="main" className="container">
                    {this.renderComponent()}
                </main>
            </React.Fragment>

        );
    }
}


const mapStateToProps = (currentState) => {
    return { };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(AccountWrapper);