import React, { Component } from 'react';

class ProductSepcifications extends Component {

    render() {
        return (
            <div className="col-12 col-md-6 col-xl-6">
                <span className="font-weight-bold">{this.props.specs.title}</span>
                <ul className="text-black-50">
                    {
                        this.props.specs.list.map((elem, eIndex) => (
                            <li key={eIndex}>{elem.value}</li>
                        ))
                    }
                </ul>
            </div>
        );
    }
}

export default ProductSepcifications;