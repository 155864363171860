import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import { connect } from 'react-redux';

import * as service from '../../../Service/service';


class ReportIssue extends Component {

    state = {
        reason: '',
        description: '',
        reasonList: [],
        issueList: [],
        issueType: '',
        isUpdated: false,
        updating : false
    }


    reportIssue = () => {
        this.setState({
            ...this.state,
            updating : true
        })
        service.reportIssue({
            issueItemId: this.props.reportIssue.data.issueItemId,
            reason: this.state.reason,
            description: this.state.description,
            issueType: this.state.issueType,
        }).then((response) => {

            this.setState({
                ...this.state,
                issueList: response.data,
                isUpdated: true,
                updating : false,
            });

        }, (error) => {
            console.log(error);

        }
        );
    }


    getAllReasons = () => {
        service.getReasons(this.props.reportIssue.data.type).then((response) => {
            this.setState({
                ...this.state,
                reasonList: response.data,
                issueType: this.props.reportIssue.data.type
            })
        }, (error) => {
            console.log(error);
        });
    };


    onReasonSelect = (event) => {
        this.setState({
            ...this.state,
            reason: event.target.value
        }, () => {
            console.log(this.state);
        });
    }

    onDescInput = (e) => {
        this.setState({
            ...this.state,
            description: e.target.value
        }, () => {
            console.log(this.state);
        });
    }

    componentWillReceiveProps() {
        this.setState(
            {
                ...this.state,
                isUpdated:false , 
                reason:'Select the reason',
                description:''
            });
        window.setTimeout(() => {
            if (this.props.reportIssue.showModal) {
                this.getAllReasons();
            }
        }, 100)
    }

        componentDidMount(){
            this.setState({description:'',reason:''})
        }


    render() {
        return (
            <Modal visible={this.props.reportIssue.showModal} onClickBackdrop={this.props.closeOrderIssueModal}>
                <div className="modal-header">
                    <h5 className="modal-title">Report Issue</h5>
                </div>
                <div className="modal-body">

                {
                                this.state.isUpdated && (
                                    <div className="col-12">
                                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                                            Your issue has been submitted succesfully.
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                )
                            }

                    <div className="col-12" style={{ display: this.props.reportIssue.data.type == 'order' ? 'block' : 'none' }}>
                        <div className="alert alert-secondary" role="alert">
                            <ul>
                                {/* <li>Return request will be submitted once you select a reason for return.</li>
                                <li>We may charge some early return charges from deposit, if applicable.</li>
                                <li>Any active deposit will get returned post we collect items from your address.</li> */}
                                <li>In case of early return, security deposit will be fortified</li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-12">
                        <form>
                            <label htmlFor="issueReason" className="font-weight-bold">Select an Issue</label>
                            <select value={this.state.reason} onChange={this.onReasonSelect}  className="browser-default custom-select" id="issueReason" placeholder="Select an Issue">
                                <option>Select the reason</option>
                                {this.state.reasonList.map((issue,iKey) => (
                                    <option key={iKey} value={issue.reason}>{issue.reason}</option>
                                ))}
                            </select>
                            <br />
                            <div className="mt-2">
                                <label htmlFor="description" className="font-weight-bold">Description</label>
                                <textarea value={this.state.description} onChange={this.onDescInput} className="form-control" id="description" rows="3" placeholder="Describe your issue"></textarea>
                            </div>
                        </form>
                    </div>

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" disabled={this.state.updating} onClick={ this.reportIssue}>
                        Submit
                </button>
                    <button type="button" className="btn btn-primary" onClick={this.props.closeOrderIssueModal}>
                        Close
                </button>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        reportIssue: currentState.reportIssue
    };
};


const mapActionsToProps = (dispatch) => {
    return {
        closeOrderIssueModal: () => {
            dispatch({ type: 'issue.modal.order.close' })
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(ReportIssue);