import React, { Component } from 'react';
import H2Title from './../../../Generic/Element/Heading';
import ReactHtmlParser from 'react-html-parser'; 
import * as service from './../../../../Service/service';
import validator from 'validator';
import { connect } from 'react-redux';
import * as keyclockService from './../../../../Service/keyclockService';

class RegisterComponent extends Component {

    state = {
        userData: {
            username: {
                verified : false,
                value : '',
                touched : false,
                error : ''
            },
            password: {
                verified : false,
                value : '',
                touched : false,
                error : ''
            },
            confirmpassword: {
                verified : false,
                value : '',
                touched : false,
                error : ''
            },
            mobile: {
                verified : false,
                value : '',
                touched : false,
                error : ''
            },
            name: {
                verified : false,
                value : '',
                touched : false,
                error : ''
            },
            email: {
                verified : false,
                value : '',
                touched : false ,
                error : ''
            }
        },
            onClickDisable:false

    }

    validateName = () => {
        this.setState({
            ...this.state,
            userData : {
                ...this.state.userData,
                name : {
                    ...this.state.userData.name,
                    error : this.state.userData.name.value.length >= 4  ? '' : `<div style="display:block" class="invalid-feedback">Name must be atleast 4 characters long </div>`,
                    verified : this.state.userData.name.value.length < 4 ? false : true
                }
            }
        });
    }

    validateMobile = () => {
        this.setState({
            ...this.state,
            userData : {
                ...this.state.userData,
                mobile : {
                    ...this.state.userData.mobile,
                    error : validator.isMobilePhone(this.state.userData.mobile.value,['en-IN'])  ? '' : `<div style="display:block" class="invalid-feedback">Invalid Mobile number </div>`,
                    verified : validator.isMobilePhone(this.state.userData.mobile.value,['en-IN']) ? true : false
                }
            }
        });
    }

    validatePassword = () => {
        this.setState({
            ...this.state,
            userData : {
                ...this.state.userData,
                password : {
                    ...this.state.userData.password,
                    error : this.state.userData.password.value.length >= 6  ? '' : `<div style="display:block" class="invalid-feedback">Password must be atleast 6 characters long </div>`,
                    verified : this.state.userData.password.value.length < 6 ? false : true
                    
                }
            }
        });
    }

    checkUsername = () => {

        if(this.state.userData.username.value.length < 4){
            this.setState({
                ...this.state,
                userData : {
                    ...this.state.userData,
                    username : {
                        ...this.state.userData.username,
                        error :`<div style="display:block" class="invalid-feedback">Username must be alteast 4 characters long </div>`,
                        verified : false
                    }
                }
            });
        }

        this.state.userData.username.value.length >= 4 && service.checkUsername(this.state.userData.username.value).then((response) => {
                this.setState({
                    ...this.state,
                    userData : {
                        ...this.state.userData,
                        username : {
                            ...this.state.userData.username,
                            error : response.data === false ? '' : `<div style="display:block" class="invalid-feedback">Username already exists. </div>`,
                            verified : response.data === false ? true : false
                        }
                    }
                });
        }, (error) => {
            console.log(error);
        });
    }

    matchPassword = () => {
        this.setState({
            ...this.state,
            userData : {
                ...this.state.userData,
                confirmpassword : {
                    ...this.state.userData.confirmpassword,
                    error : this.state.userData.password.value === this.state.userData.confirmpassword.value ? '' : `<div style="display:block" class="invalid-feedback">Passwords do not match. </div>`,
                    verified : this.state.userData.password.value === this.state.userData.confirmpassword.value ? true : false
                }
            }
        });
    }

    checkEmail = () => {
        if(validator.isEmail(this.state.userData.email.value) === false){
            this.setState({
                ...this.state,
                userData : {
                    ...this.state.userData,
                    email : {
                        ...this.state.userData.email,
                        error : `<div style="display:block" class="invalid-feedback">Invalid email format </div>`,
                        verified : false
                    }
                }
            });
        }
        validator.isEmail(this.state.userData.email.value) && service.checkEmail(this.state.userData.email.value).then((response) => {
            this.setState({
                ...this.state,
                userData : {
                    ...this.state.userData,
                    email : {
                        ...this.state.userData.email,
                        error : response.data === false ? '' : `<div style="display:block" class="invalid-feedback">Email address already exists. </div>`,
                        verified : response.data === false ? true : false
                    }
                }
            });
        }, (error) => {
            console.log(error);
        });
    }

    checkErrors = () => {
        let isVerified = true;
        let tmpState = JSON.parse(JSON.stringify(this.state));
        Object.keys(tmpState.userData).map((elem, index) => {
            if(tmpState.userData[elem].touched === false){
                tmpState.userData[elem].error = ` <div style="display:block" class="invalid-feedback">Please provide a valid ${elem} </div>`;
            }else if(tmpState.userData[elem].value.length === 0){
                tmpState.userData[elem].error = ` <div style="display:block" class="invalid-feedback">Please provide a valid ${elem} </div>`;
            }
            if(tmpState.userData[elem].verified === false){
                isVerified = false
            }
        });
        this.setState(tmpState);
        return isVerified;
    }

    register = () => {

        if(this.checkErrors()){
            let userObj = {
                username : this.state.userData.username.value,
                password : this.state.userData.password.value,
                confirmpassword : this.state.userData.confirmpassword.value,
                email : this.state.userData.email.value,
                firstName : this.state.userData.name.value.split(" ")[0],
                lastName : this.state.userData.name.value.split(" ").length > 1 ? this.state.userData.name.value.split(" ")[1] : '',
                contact : this.state.userData.mobile.value
            }

            service.registerUser(userObj).then((response) => {
                
                if(response.status === 200){
                    this.loginUser();
                    
                }

            }, (error) => {
                console.log(error);
            });


        }else{
            console.log('Invalid');
        }
    }

    handleChange = (e, field) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState.userData[field].value = e.target.value;
        tmpState.userData[field].touched = true;
        tmpState.userData[field].error = "";
        this.setState(tmpState);
    }

    
    loginUser = () => {

        keyclockService.getUserToken({
            username : this.state.userData.username.value,
            password : this.state.userData.password.value
        }).then((response) => {
           localStorage.setItem("keycloak",JSON.stringify(response.data));
           this.props.loginUser(response.data);
           this.props.hideLoginWindow();
        }, (err) => {
            console.log("Unable to acquire auth token");
        });

    }

    setDisabled=()=>{
        this.setState({onClickDisable:true});
    }

    render() {
        return (
            <React.Fragment>
                 <H2Title value="Let's get started" />

                <div className="form-group mt-3">
                    <input 
                        value={this.state.userData.email.value} onChange={(e) => this.handleChange(e, 'email')} type="email" 
                        onBlur={this.checkEmail}
                        className={this.state.userData.email.verified ? 'form-control is-valid' : this.state.userData.email.error.length === 0 ? 'form-control' : 'form-control is-invalid'} 
                        id="email" aria-describedby="emailHelp" placeholder="Enter email" />
                    <small id="emailHelp" className="form-text text-muted">We'll never share your email
                                            with anyone else.</small>
                    { ReactHtmlParser(this.state.userData.email.error.toString()) }
                </div>

                <div className="form-group">
                    <input 
                        value={this.state.userData.username.value} onChange={(e) => this.handleChange(e, 'username')} type="text" 
                        onBlur={this.checkUsername}
                        className={this.state.userData.username.verified ? 'form-control is-valid' : this.state.userData.username.error.length === 0 ? 'form-control' : 'form-control is-invalid'} 
                        id="username"  placeholder="Username" />
                    { ReactHtmlParser(this.state.userData.username.error.toString()) }
                </div>

                <div className="form-group">
                    <input value={this.state.userData.name.value} onChange={(e) => this.handleChange(e, 'name')} type="text" 
                        onBlur={this.validateName}
                        className={this.state.userData.name.verified ? 'form-control is-valid' : this.state.userData.name.error.length === 0 ? 'form-control' : 'form-control is-invalid'} 
                        id="name"placeholder="Name" />
                     { ReactHtmlParser(this.state.userData.name.error.toString()) }
                </div>
                <div className="form-group">
                    <input value={this.state.userData.mobile.value} onChange={(e) => this.handleChange(e, 'mobile')} type="text"
                        onBlur={this.validateMobile}
                        className={this.state.userData.mobile.verified ? 'form-control is-valid' : this.state.userData.mobile.error.length === 0 ? 'form-control' : 'form-control is-invalid'} 
                        id="mobile" placeholder="Mobile No." />
                     { ReactHtmlParser(this.state.userData.mobile.error.toString()) }
                </div>
                <div className="form-group">
                    <input value={this.state.userData.password.value} onChange={(e) => this.handleChange(e, 'password')} type="password"
                        onBlur={this.validatePassword}
                        className={this.state.userData.password.verified ? 'form-control is-valid' : this.state.userData.password.error.length === 0 ? 'form-control' : 'form-control is-invalid'} 
                        id="password" placeholder="Password" />
                     { ReactHtmlParser(this.state.userData.password.error.toString()) }
                </div>
                <div className="form-group">
                    <input value={this.state.userData.confirmpassword.value} onChange={(e) => this.handleChange(e, 'confirmpassword')} type="password"
                        onBlur={this.matchPassword}
                        className={this.state.userData.confirmpassword.verified ? 'form-control is-valid' : this.state.userData.confirmpassword.error.length === 0 ? 'form-control' : 'form-control is-invalid'} 
                        id="confirmpassword" placeholder="Confirm Password" />
                     { ReactHtmlParser(this.state.userData.confirmpassword.error.toString()) }
                </div>
                
                <button type="button" onClick={()=>{ this.register();this.setDisabled();}} className="btn btn-primary btn-sm btn-block" disabled={this.state.onClickDisable}>Register</button>
                <button type="button" onClick={(e) => this.props.changeScreen('login')} className="btn btn-light btn-sm btn-block">Sign In</button>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        userState: currentState.userState,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        loginUser: (data) => {
            dispatch({ type: 'user.auth.login', data: data });
        },
        hideLoginWindow: (data) => {
            dispatch({ type: 'login.window.hide', data: data });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(RegisterComponent);