import React, { Component } from 'react';
import H2Title from './../../Generic/Element/Heading';

import * as service from './../../../Service/service';
import IosCard from 'react-ionicons/lib/IosCard';
import IosCashOutline from 'react-ionicons/lib/IosCashOutline';
import IosCardOutline from 'react-ionicons/lib/IosCardOutline';
import InvoiceMonth from './InvoiceMonth';
import ReportIssue from './../../Generic/Modal/reportIssue';

import keys from './../../../Config/config.json';

import { connect } from 'react-redux';

class InvoiceComponent extends Component {

    state = {
        invoice: {},
        total: {
            paid: 0,
            unpaid: 0,
            total: 0
        },
        month: {
            paid: 0,
            unpaid: 0,
            total: 0
        },
        isLoaded: false,
        selectedInvoice: '',
        bulkPayment: false,
        bulkPaymentType: '',
        razorPayKey: '',
        isupdating: false,
        allupdating:false,
        monthlyupdating:false
    }

    styles = {
        parentBox: {
            border: '1px solid rgba(72, 94, 144, 0.16)',
            wordWrap: 'break-word',
            backgroundColor: '#fff',
            backgroundClip: 'border-box',
            borderRadius: '0.25rem'
        },
        title: {
            fontSize: '16px', color: "#004980"
        },
        info: {
            fontSize: '20px', color: "#e8554e",
            position: 'relative', top: '4px'
        }
    }

    getRazorPayKeys = () => {

        service.razorPayKeys().then((response) => {
            this.setState({
                razorPayKey: response.data
            });

        }, (error) => {
            console.log(error);
        });
    }

    listAllInvoices = () => {

        var total = {
            paid: 0,
            unpaid: 0,
            total: 0
        };


        var monthData = {
            paid: 0,
            unpaid: 0,
            total: 0
        }

        service.listInvoice().then((response) => {

            let invoiceList = {};

            response.data.length != 0 && response.data.map((invoice) => {

                let month = new Date(invoice.generatedOn).toDateString().split(" ")[1];
                let year = new Date(invoice.generatedOn).toDateString().split(" ")[3];
                let final = `${month}-${year}`

                if (typeof invoiceList[final] == "undefined") {
                    invoiceList[final] = [];
                    invoiceList[final].push(invoice);
                } else {
                    invoiceList[final].push(invoice);
                }

                if (invoice.isPaid) {
                    total.paid += parseFloat(invoice.amount + invoice.interestGenerated);
                } else {
                    total.unpaid += parseFloat(invoice.amount + invoice.interestGenerated);
                }

                if (new Date().toDateString().split(" ")[1] + "-" + new Date().toDateString().split(" ")[3] ==
                    new Date(invoice.generatedOn).toDateString().split(" ")[1] + "-" + new Date(invoice.generatedOn).toDateString().split(" ")[3]
                ) {

                    if (invoice.isPaid) {
                        monthData.paid += parseFloat(invoice.amount + invoice.interestGenerated);
                    } else {
                        monthData.unpaid += parseFloat(invoice.amount + invoice.interestGenerated);
                    }

                }

            });

            this.setState({
                ...this.state,
                invoice: invoiceList,
                isLoaded: true,
                total: total,
                month: monthData
            });

        }, (error) => {
            console.log(error);
        });
    }

    generateRazorPayOrderId = (invoiceNumber) => {

        this.setState({
            ...this.state,
            selectedInvoice: invoiceNumber,
            bulkPayment: false,
            bulkPaymentType: ''
        });

        service.getRazorpayOrderIdInvoice(invoiceNumber).then((response) => {
            this.setState({
                ...this.state,
                razorpayInfo: response.data
            });

            setTimeout(() => {
                this.initiatePayment();
            }, 100);
        }, (error) => {
            console.log(error);
        });
    }

    initiatePayment = () => {
        var options = {
            "key": this.state.razorPayKey,
            "amount": this.state.razorpayInfo.amount_due,
            "currency": this.state.razorpayInfo.currency,
            "name": "Voorent Pvt. Ltd",
            "description": "",
            "image": "https://voorent.com/voorent-storage/application/voorent.png",
            "order_id": this.state.razorpayInfo.id,
            "handler": (response) => {
                this.updateRazorpayPayments(response);
            },
            "modal": {
                "ondismiss": () => {
                    this.listAllInvoices();
                }
            },
            "prefill": {
                "name": `${this.props.userInfo.firstName} ${this.props.userInfo.lastName}`,
                "email": `${this.props.userInfo.email}`,
                "contact": `${this.props.userInfo.contact}`
            },
            "theme": {
                "color": "#01497f"
            }
        };

        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    updateRazorpayPayments = (paymentUpdate) => {

        
        if(this.state.bulkPaymentType.toUpperCase()=='ALL'){
            this.setState({allupdating:true});
        }
        else if(this.state.bulkPaymentType.toUpperCase()=='MONTHLY'){
            this.setState({monthlyupdating:true});
        }
        else{
            this.setState({isupdating:true});
        }


        let obj = {
            razorpayPaymentId: paymentUpdate.razorpay_payment_id,
            razorpayOrderId: paymentUpdate.razorpay_order_id,
            razorpaySignature: paymentUpdate.razorpay_signature,
            invoiceNumber: '',
            bulkNumber: '',
        };

        if (this.state.bulkPayment) {
            obj.invoiceNumber = this.state.bulkPaymentType.toUpperCase();
            obj.bulkNumber = this.state.razorpayInfo.receipt;
        } else {
            obj.invoiceNumber = this.state.selectedInvoice;
            obj.bulkNumber = 'abc';
        }

        service.updatePaymentInvoice(obj).then((response) => {

            this.setState({
                ...this.state,
                paymentConfirmation: response.data,
                isLoaded: true,
                isupdating: false,
                allupdating:false,
                monthlyupdating:false
            });

            if (response.data) {

                this.listAllInvoices();

            } else {
                this.setState({
                    ...this.state,
                    isupdating: false,
                    allupdating:false,
                    monthlyupdating:false
                });
    
            }

        }, (error) => {
            console.log(error);
        });
    };

    initiateBulkInvoicePayment = (type) => {

        service.bulkInvoicePayment(type).then(response => {
            this.setState({
                ...this.state,
                razorpayInfo: response.data,
                bulkPayment: true,
                bulkPaymentType: type
            });
            setTimeout(() => {
                        
                this.initiatePayment();
            }, 500);
        }, error => {
            console.log(error);
        });

    }

    componentDidMount() {
        this.listAllInvoices();
        this.getRazorPayKeys();
    }

    render() {
        return (
            <React.Fragment>
                <div className="row mt-3 mb-3">
                    <div className="col-12">
                        <H2Title value="Invoices" />

                        <div className="row">
                            <div className="col-12 col-md-6 mt-2">
                                <div className="row ml-1 mr-1 p-2" style={this.styles.parentBox}>
                                    <div className="col-4">
                                        <IosCard fontSize="64px" color="#004980" />
                                    </div>
                                    <div className="col-8 p-2 text-right">
                                        <span className="d-block text-uppercase" style={this.styles.title}>Unpaid Total</span>
                                        <span className="d-inline-block font-weight-bold" style={this.styles.info}>Rs {this.state.total.unpaid}</span>
                                        <button  onClick={() => this.initiateBulkInvoicePayment('all')} className={this.state.total.unpaid == 0 ? 'btn btn-sm btn-outline-success ml-2 d-inline-block cursor-pointer disabled' : 'btn btn-sm btn-outline-success ml-2 d-inline-block cursor-pointer'} disabled={this.state.allupdating}>
                                        {
                                            this.state.allupdating?<div><span className="ml-2">Processing &nbsp;</span><span className="spinner-border spinner-border-sm"  role="status"></span></div>:"Clear Dues"
                                        }
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mt-2">
                                <div className="row ml-1 mr-1 p-2" style={this.styles.parentBox}>
                                    <div className="col-4">
                                        <IosCardOutline fontSize="64px" color="#004980" />
                                    </div>
                                    <div className="col-8 p-2 text-right">
                                        <span className="d-block text-uppercase" style={this.styles.title}>Total this month</span>
                                        <span className="d-block font-weight-bold" style={this.styles.info}>Rs {this.state.month.paid + this.state.month.unpaid}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row element-border mt-3 mr-1 ml-1" >
                            <div className="col-12 p-3">

                                {
                                    !this.state.isLoaded && (<div className="col-12 m-auto" style={{ minHeight: '250px' }}>
                                        <div className="row">
                                            <div className="col-12 text-center mt-5">
                                                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <span className="pl-3 text-black-50 d-block" >Checking backend . . </span>
                                            </div>
                                        </div>
                                    </div>)
                                }

                                {
                                    this.state.isLoaded && Object.keys(this.state.invoice).length == 0 && (
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 text-center mt-5">
                                                    <img height="150px" src="./svg/invoice.svg" />
                                                    <h5 className="mt-3 mb-5">No invoices present, Your invoices will be shown here once you rent something with us.</h5>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    Object.keys(this.state.invoice).map((invoiceKey, iIndex) => (
                                        <div key={iIndex}>

                                            <InvoiceMonth key={iIndex} invoices={this.state.invoice[invoiceKey]} 
                                                month={invoiceKey} makePayment={this.generateRazorPayOrderId} 
                                                updating={this.state.isupdating}  selectedInvoice = {this.state.selectedInvoice}
                                            />
                                            
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                    <ReportIssue />
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        userInfo: currentState.userState.userInfo
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(InvoiceComponent);