import React, { Component } from 'react';
import H2Title from './../../Generic/Element/Heading';

import * as service from './../../../Service/service';

class ChangePasswordComponent extends Component {

    state = {
        password: {
            value: '',
            valid: ''
        },
        confirm: {
            value: '',
            valid: ''
        },
        dataPassed:[],
        message: '',
        showInfo:false,
        infoType: 'alert-danger',
        isDisabled:false

    }

    logout = () => {
        delete localStorage.keycloak;
        window.location.href="./"
    }

    changePassword = () => {


        this.setState({
            ...this.state,
            showInfo: false,
            isDisabled:true,
            infoType:'alert-danger'
        });

        if(this.state.password.value !== this.state.confirm.value){
            this.setState({
                ...this.state,

                isDisabled:false,
                message: 'Passwords not matching. Kindly check and re-try.',
                showInfo:true,
            });
        }else if(this.state.password.value.length < 6){
            this.setState({
                ...this.state,
                isDisabled:false,
                showInfo: true,
                message: "Passwords length must be at-least 6 characters.",
                
            });
        }
        else{

            console.log(this.state.password.value);
            service.changePassword({
                password : this.state.password.value,
            }).then((response) => {
                   
                    this.setState({
                        ...this.state,
                        dataPassed:response.data,
                        message : "Password changed successfully",
                        showInfo: true,
                        infoType: 'alert-success',
                        isDisabled:false
                    });
                    
                
            }, (error) => {

                console.log(error);
                this.setState({
                    ...this.state,
                    infoType: 'alert-danger',
                    message: "Something went wrong. Please try again after sometime.",
                    showInfo: true,
                    isDisabled:false
                });
            });
        }
    }
        
        

    showInfoMessage = () => {
        return this.state.showInfo ? "alert alert-dismissible fade show " + this.state.infoType : "alert alert-dismissible fade";
    }

    handleChange = (e, field) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState[field].value = e.target.value;
        this.setState(tmpState);
    }

    setIsDisabled=()=>{
        this.setState({isDisabled:true});
    };

    

    render() {
        return (
            <div className="row mt-5" style={{ height: '100%' }}>
                <div className="col-5 col-md-5 d-none d-md-block" style={{ minHeight: '100%', backgroundPosition: '695px -409px', backgroundImage: 'url("https://images.unsplash.com/photo-1532103054090-3491f1a05d0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80")' }}>

                </div>
                <div className="col-12 col-md-7 p-5 mb-auto mt-auto position-relative" style={{ minHeight: '450px' }}>
                    <H2Title value="Change Password" />


                    {
                                this.state.showInfo && (
                                    <div className={this.showInfoMessage()} role="alert">
                                        <span>{this.state.message}</span>

                                        {}
                                    </div>
                                )
                            }


                    <div className="form-group mt-2">
                        <input value={this.state.password.value} onChange={(e) => this.handleChange(e, 'password')} type="password"
                            className="form-control" placeholder="New Password" />
                    </div>
                    <div className="form-group mt-2">
                        <input value={this.state.confirm.value} onChange={(e) => this.handleChange(e, 'confirm')} type="password"
                            className="form-control" placeholder="Confirm Password" />
                    </div>

                    <button className="btn btn-primary" type="button" onClick={this.changePassword} disabled={this.state.isDisabled}>
                        Submit
                    </button>

                </div>
            </div>
        );
    }
}

export default ChangePasswordComponent;