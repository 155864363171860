import React, { Component } from 'react';

import PaymentItem from './PaymentItem';

class PaymentList extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-12 mb-3 font-weight-bold mt-3">
                    <span>{this.props.month}</span>
                </div>

                {
                    this.props.payment.map((paymentItem, piIndex) => (
                        <React.Fragment key={piIndex}>
                            <PaymentItem item={paymentItem} />
                            { piIndex == this.props.payment.length -  1 ? '' : (<hr id={piIndex} className="mt-3 mb-3" />)}
                        </React.Fragment>
                    ))
                }
            </div>
        );
    }
}

export default PaymentList;