import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import { connect } from 'react-redux';

class UnPaidInvoice extends Component {
    state = {}
    render() {
        return (
            <Modal visible={this.props.unpaidInvoiceModal.showModal} onClickBackdrop={this.props.closeUnpaidInvoiceModal} >
                <div className="modal-header">
                    <h5 className="modal-title">Extend Order</h5>
                </div>
                <div className="modal-body" >
                    <div className="col-12">
                        <div className="alert alert-secondary" role="alert">

                            <div className="row">
                                <div className="col-12 text-left">
                                        <span className="d-block">Dear Customer,</span>
                                        <span className="d-blockl">You can only extend an order if your last invoices are paid.</span>
                                        <span className="d-block mt-2">Please check Account > Invoices , for unpaid invoices.</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="modal-footer">

                    <button type="button" className="btn btn-primary" onClick={this.props.closeUnpaidInvoiceModal}>
                        Close
                    </button>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        unpaidInvoiceModal: currentState.unpaidInvoiceModal
    };
};


const mapActionsToProps = (dispatch) => {
    return {
        closeUnpaidInvoiceModal: () => {
            dispatch({ type: 'invoice.unpaid.showModal.false' })
        },

    };
}
export default connect(mapStateToProps, mapActionsToProps)(UnPaidInvoice);