import React, { Component } from 'react';
import H2Title from './../../../Generic/Element/Heading';

import * as service from './../../../../Service/service';

class ForgotComponent extends Component {

    state = {
        email: '',
        loading: false,
        message: 'Reset Password',
        info: false
    }

    handleInput = (e) => {
        this.setState({
            ...this.state,
            email: e.target.value
        })
    }

    forgotMail = () => {
        this.setState({
            ...this.state,
            message: 'Please wait .',
            loading: true
        });
        service.passwordResetMail(this.state.email).then((response) => {
            if (response.data) {

                this.setState({
                    ...this.state,
                    message: 'Reset Password',
                    loading: false,
                    info : true
                });

            }
        }, (error) => {
            console.log(error);
        });
    }

    render() {
        return (
            <React.Fragment>
                <H2Title value="Get your password back" />

                {
                    this.state.info && (
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <strong>Success!</strong> Please check your registered email for instructions.
                                <button type="button" onClick={
                                    () => this.setState({
                                        ...this.state,
                                        info : false
                                    })
                                 } className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    )
                }

                {
                    !this.state.info && (
                        <div>
                            <div className="form-group mt-3">
                                <input value={this.state.email} onChange={this.handleInput} type="email" className="form-control" id="exampleInputEmail1"
                                    aria-describedby="emailHelp" placeholder="Enter email Id" />
                            </div>
                            <div className="form-group">
                                <small id="emailHelp" className="form-text text-muted text-left"
                                >We will send password reset link to registered email Id.
                                </small>
                            </div>
                        </div>
                    )
                }

                <button type="button" onClick={this.forgotMail} className="btn btn-primary btn-sm btn-block">
                    {
                        this.state.loading && (
                            <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                        )
                    }
                    {this.state.message}
                </button>

                <button type="button" onClick={(e) => this.props.changeScreen('login')} className="btn btn-success btn-sm btn-block">Sign In</button>
            </React.Fragment>
        );
    }
}

export default ForgotComponent;