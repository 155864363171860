import React, { Component } from 'react';
import IosBasketOutline from 'react-ionicons/lib/IosBasketOutline';
import IosCard from 'react-ionicons/lib/IosCard';
import IosBasket from 'react-ionicons/lib/IosBasket';
import IosPaperOutline from 'react-ionicons/lib/IosPaperOutline';

import * as service from './../../../Service/service';

class HomeComponent extends Component {

    styles = {
        parentBox: {
            border: '1px solid rgba(72, 94, 144, 0.16)',
            wordWrap: 'break-word',
            backgroundColor: '#fff',
            backgroundClip: 'border-box',
            borderRadius: '0.25rem'
        },
        title: {
            fontSize: '16px', color: "#004980"
        },
        info: {
            fontSize: '24px', color: "#e8554e"
        }
    }

    state = {
        activeOrders: 0,
        allOrders: 0,
        pendingInvoices: 0,
        activeDeposits: 0
    }

    componentDidMount(){
        this.getOrderInfo();
    }

    getOrderInfo = () => {

        service.orderInfo().then((response) => {

            this.setState({
                ...this.state,
                ...response.data
            });

        }, (error) => {
            console.log(error);
        });

    }

    render() {
        return (
            <React.Fragment>
                <div className="row mt-5" >

                    <div className="col-12 col-md-3 mt-2">
                        <div className="row ml-1 mr-1 p-2" style={this.styles.parentBox}>
                            <div className="col-4">
                                <IosBasketOutline fontSize="64px" color="#004980" />
                            </div>
                            <div className="col-8 p-2 text-right">
                                <span className="d-block text-uppercase" style={this.styles.title}>Active Orders</span>
                                <span className="d-block font-weight-bold" style={this.styles.info}>{this.state.activeOrders}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 mt-2">
                        <div className="row ml-1 mr-1 p-2" style={this.styles.parentBox}>
                            <div className="col-4">
                                <IosBasket fontSize="64px" color="#004980" />
                            </div>
                            <div className="col-8 p-2 text-right">
                                <span className="d-block text-uppercase" style={this.styles.title}>All Orders</span>
                                <span className="d-block font-weight-bold" style={this.styles.info}>{this.state.allOrders}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 mt-2">
                        <div className="row ml-1 mr-1 p-2" style={this.styles.parentBox}>
                            <div className="col-4">
                                <IosCard fontSize="64px" color="#004980" />
                            </div>
                            <div className="col-8 p-2 text-right">
                                <span className="d-block text-uppercase" style={this.styles.title}>Pending</span>
                                <span className="d-block font-weight-bold" style={this.styles.info}>₹ {this.state.pendingInvoices}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 mt-2">
                        <div className="row ml-1 mr-1 p-2" style={this.styles.parentBox}>
                            <div className="col-4">
                                <IosPaperOutline fontSize="64px" color="#004980" />
                            </div>
                            <div className="col-8 p-2 text-right">
                                <span className="d-block text-uppercase" style={this.styles.title}>Deposits</span>
                                <span className="d-block font-weight-bold" style={this.styles.info}>₹ {this.state.activeDeposits}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="row mt-5">
                    <div className="col-12 ml-1 mr-1 p-2">
                        <img style={{ width: 'inherit' }} src="http://voorent-new.s3-website.ap-south-1.amazonaws.com/images/two.jpeg" alt="" />
                    </div>
                </div> */}
            </React.Fragment>
        );
    }
}

export default HomeComponent;