import React, { Component } from 'react';


class IssueElement extends Component {

    render() {
        return (

            <div className="col-12 p-3">
                <div className="row">
                    <div className="col-8 col-md-10">
                        <div className="row">
                            <div className="col-12">
                                <span className="font-weight-bold">Issue For : </span>
                                <span className="text-uppercase ">{this.props.issueList.issueType}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <span className="font-weight-bold">Reason :</span>
                                <span className="text-black-50">{this.props.issueList.reason}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <span className="font-weight-bold">Description : </span>
                                <span className="text-black-50">{this.props.issueList.description}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <span className="text-danger d-block" >Voorent Support :</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-2 ">
                        <span className="font-weight-bold d-block">Status</span>
                        <span className={this.props.issueList.isClosed==true ?"text-success":"text-warning"}>{(this.props.issueList.isClosed == true) ? 'RESOLVED':'PENDING'} </span>
                    </div>

                </div>
            </div>
        );
    }
}

export default IssueElement;