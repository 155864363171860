import React, { Component } from 'react';

import Modal from 'react-bootstrap4-modal';
import { connect } from 'react-redux';
import * as service from './../../../../Service/service';
import ReactGA from 'react-ga';
import H2Title from './../../../Generic/Element/Heading';

class ResponseForm extends Component {
    state = {
        isUpdated:false,
        mobile:'',
        name:'',
        email:'',
     }

     

     onInput=(e)=>{
        this.setState({
            ...this.state,
            [e.target.name] : e.target.value
        }, () => {
            console.log(this.state);
        });
     }
     submitData=()=>{
         this.setState({
             updating:true
         })
         service.submitSurvey({
            name: this.state.name,
            email: this.state.email,
            mobileNo: this.state.mobile,
        }).then((response) => {

            this.setState({
                ...this.state,
                isUpdated: true,
                message:'Your response has been submitted successfully!',
                updating : false,
                styling : 'alert alert-success alert-dismissible fade show'
            });
            ReactGA.initialize('AW-580612177/TUJrCLy6wPUBENHg7ZQC');
            ReactGA.pageview(window.location.pathname + window.location.search);

            window.setTimeout(function(){

                // Move to a new location or you can do something else
                window.location.href=window.location.origin + '/thankyou'

            }, 600);

        }, (error) => {
            this.setState({
                message:'Something went wrong!',
                isUpdated:true,
                styling : 'alert alert-danger alert-dismissible fade show'
            })
        }
        );
     }

    render() { 
        return ( 
            <React.Fragment>
                
                <Modal setTimeout='5000' visible={this.props.surveyModal.show} onClickBackdrop={this.props.closeSurveyModal}>
                <div className="row" style={{height:'100%'}}>
                        <div className="col-5 col-md-6 d-none d-md-block" style={{minHeight:'100%',overflow: 'hidden'}}>
                                <img className="lazyload mt-4 mb-1 mr-2" src="https://voorent.com/voorent-storage/images/image.png" alt="" style={{maxWidth:460 , marginLeft:20}} />
                        </div>

                <div className="col-12 col-md-6 p-5 mb-auto mt-auto position-relative" style={{minHeight:'450px'}}>
                <div className="ml-2">
                <H2Title  value="Signup For More Offers" />
                </div>
                {
                                this.state.isUpdated && (
                                    <div className="col-12">
                                        <div className={this.state.styling} role="alert">
                                            {this.state.message}.
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
 
                    <div className="col-12">
                        <form>
                            <div className="mt-2">
                                <label htmlFor="name" className="font-weight-bold">Name</label>
                                <input name='name' value={this.state.name} onChange={this.onInput} className="form-control" id="name" placeholder="Enter name"/>
                            </div>
                            <div className="mt-2">
                                <label htmlFor="email" className="font-weight-bold">Email</label>
                                <input name='email' value={this.state.email} onChange={this.onInput} className="form-control" id="email" placeholder="Enter email"/>
                            </div>
                            <div className="mt-2">
                                <label htmlFor="Mobile" className="font-weight-bold">Mobile</label>
                                <input name='mobile' value={this.state.mobile} onChange={this.onInput}  className="form-control" id="Mobile" placeholder="Enter mobile"/>
                            </div>
                            
                        </form>
                        <button type="button" className="btn btn-success mt-4 col-12" disabled={this.state.updating} onClick={this.submitData}>
                        Submit
                        </button>
                        <button type="button" className="btn btn-primary mt-2 col-12" onClick={this.props.closeSurveyModal}>
                            Close
                        </button>
                        
                    </div>
                    

                </div>
                
                </div>
            </Modal>
            
            </React.Fragment>
         );
    }
}
 
const mapStateToProps = (currentState) => {
    return {
        surveyModal: currentState.surveyModal
    };
};


const mapActionsToProps = (dispatch) => {
    return {
        closeSurveyModal: () => {
            dispatch({ type: 'survey.hide' })
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(ResponseForm);