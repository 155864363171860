import React, { Component } from 'react';
import MdClose from 'react-ionicons/lib/MdClose';

class AddressElement extends Component {
    render() {
        return (
            <div className="col-12 p-3">
                <div className="row">
                    <div className="col-8 col-md-10">
                        <span className="text-uppercase d-block">{this.props.address.addressType}</span>
                        <span className="font-weight-bold">{this.props.address.name}</span> ,
                         <span className="font-weight-bold"> {this.props.address.contactNo} </span> 
                        <span className="d-block">
                            {this.props.address.houseNo} {this.props.address.address1} {this.props.address.address2} 
                            <br/>
                            {this.props.address.city} - {this.props.address.pincode}</span>
                    </div>
                    <div className="col-4 col-md-2 text-right">
                        <button onClick={(e) => this.props.deleteAddress(this.props.address.Id)} className="btn btn-danger btn-sm btn-block"><MdClose color="#ffffff" /><span>Delete</span></button>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddressElement;