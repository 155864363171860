import React, { Component } from 'react';
import { connect } from 'react-redux';


class DepositItem extends Component {

    handleIssue = (type,orderNumber) => {
        this.props.showDepositIssueModal({
            type : type,
            issueItemId : orderNumber
        });
    };

    statusColor=(depositStatus)=>{
        if(depositStatus=='ACTIVE'){
            return "text-success text-nowrap";
        }
        else if(depositStatus == 'WAITING_PAYMENT'){
            return "text-warning text-nowrap";
        }
        else{
            return "text-danger text-nowrap"
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="col-12">
                    <div className="row">
                        <div className="col-6 col-md-2">
                            <span className="d-block">Deposit Date</span>
                            <span className="text-black-50">{new Date(this.props.item.txnDate).toDateString()}</span>
                        </div>
                        <div className="col-6 col-md-2">
                            <span className="d-block">Order Number</span>
                            <span className="text-black-50">{this.props.item.orderNum}</span>
                        </div>
                        <div className="col-6 col-md-2">
                            <span className="d-block">Txn. Type</span>
                            <span className="text-black-50">{this.props.item.txnType}</span>
                        </div>

                        <div className="col-6 col-md-2">
                            <span className="d-block">Txn.ID / Ref No.</span>
                            <span className="text-black-50">{this.props.item.paymentId == 0 ? 'NA' : this.props.item.paymentId}</span>
                        </div>

                        <div className="col-6 col-md-2">
                            <span className="d-block">Amount</span>
                            <span className="text-black-50">Rs {this.props.item.amount}</span>
                        </div>

                        <div className="col-6 col-md-2">
                            <span className="d-block">Status</span>
                            <span className={this.statusColor(this.props.item.depositStatus)}>{this.props.item.txnType=='REFUND'?'REFUNDED':this.props.item.depositStatus}</span>
                        </div>



                        <div className="col-12">
                            <div className="row mt-2">
                                <div className="col-12 col-md-2">
                                    <span className="text-primary" style={{ cursor: 'pointer' }} onClick={() => this.handleIssue('deposit',this.props.item.orderNum)}>Report Issue</span>
                                </div>
                                {/* <div className="col-12 col-md-2">
                                    <span className="text-primary" style={{ cursor: 'pointer' }}>View Adjustments</span>
                                </div>

                                <div className="col-12 col-md-2">
                                    <span className="text-primary" style={{ cursor: 'pointer' }}>Check Status</span>
                                </div> */}

                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (currentState) => {
    return {
        reportIssue: currentState.reportIssue,

    };
};

const mapActionsToProps = (dispatch) => {
    return {

        showDepositIssueModal: (data) => {
            dispatch({ type: 'issue.modal.deposit.open', data : data });
        },

    };
}

export default connect(mapStateToProps, mapActionsToProps)(DepositItem);