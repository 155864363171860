import React, { Component } from 'react';
import { connect } from 'react-redux';

class BreadCrumbs extends Component {

    render() {
        return (
            <React.Fragment>

                <div role="main" style={{ background: 'rgb(248, 249, 250)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <nav className="navbar navbar-expand-lg navbar-light justify-content-start" >
                                    <div className="navbar navbar-expand-lg">
                                        <div >
                                            <ul className="navbar-nav d-block">
                                                {
                                                    this.props.breadcrumbs.navigation.map((nav, nIndex) => (
                                                        <li className="nav-item d-inline-block" key={nIndex}>
                                                            <a className={nIndex == 0 ? 'nav-link text-capitalize' : 'nav-link bcrumb text-capitalize'} href={nav.link}>{nav.name}</a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>

                            <div style={{ display: this.props.breadcrumbs.showSidebar ? 'block' : 'none' }} className="col-6">
                                <nav className="navbar navbar-expand-lg navbar-light justify-content-end">
                                    <div className="navbar navbar-expand-lg">
                                        <div>
                                            <ul className="navbar-nav ">
                                                {
                                                    this.props.breadcrumbs.sidebar.map((item, iIndex) => (
                                                        <li className="nav-item" key={iIndex}>
                                                            <a className={iIndex == 0 ? 'nav-link text-capitalize' : 'nav-link bcrumb text-capitalize'} href={item.link}>{item.name}</a>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (currentState) => {
    return { 
        breadcrumbs : currentState.breadcrumbs
    };
};

const mapActionsToProps = (dispatch) => {
    return {};
}

export default connect(mapStateToProps, mapActionsToProps)(BreadCrumbs);