import React, { Component } from "react";
import FooterComponent from "./../../Generic/Footer/FooterComponent";
import HeaderComponent from "./../../Generic/Header/HeaderComponent";

import BreadCrumbs from "./../../Generic/BreadCrumbs/BreadCrumbs";
import FilterComponent from "./Filters/FilterComponent";
import H2Title from "./../../Generic/Element/Heading";

import SingleProduct from "./../../Generic/Element/SingleProduct";
import * as service from "./../../../Service/service";
import { Helmet } from 'react-helmet';

import { connect } from "react-redux";

class CategoryComponent extends Component {
  state = {
    categorySelected: "",
    subCategorySelected: "",
    categoryList: [],
    subCategoryList: [],
    sortedProducts: [],
    allProduct: [],
    isLoaded: false,
  };

  componentDidMount() {
    this.getCategoryList();

    this.props.updateBreadcumbs({
      navigation: [
        { name: "Home", link: "./" },
        { name: "Products", link: "#" },
      ],
      showSidebar: false,
      sidebar: [],
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      categorySelected: props.match.params.category,
    };
  }

  changeCategory = (e) => {
    this.setState({
      ...this.state,
      categorySelected: e.target.value,
      isLoaded: false,
      allProduct: [],
      sortedProducts: [],
    });

    this.getSubCategoryList(e.target.value);

    this.getProductByCategory(e.target.value);
  };

  getCategoryList = () => {
    service.getAllCategories().then(
      (response) => {
        this.setState({
          ...this.state,
          categoryList: response.data,
        });

        this.getProductByCategory(this.state.categorySelected);
        this.getSubCategoryList(this.state.categorySelected);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  getSubCategoryList = (category) => {
    service.getSubCategories(category).then(
      (response) => {
        this.setState({
          ...this.state,
          subCategoryList: response.data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  getProductByCategory = (category) => {
    service.getProductByCategory(category).then(
      (response) => {
        this.setState({
          ...this.state,
          allProduct: response.data,
          sortedProducts: response.data,
          isLoaded: true,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  checkSelectedCategory = (slug) => {
    if (slug === this.state.categorySelected) {
      return true;
    } else {
      return false;
    }
  };

  checkSelectedSubCategory = (slug) => {
    return slug === this.state.subCategorySelected ? true : false;
  };

  changeSubCategory = (e) => {
    this.setState({
      ...this.state,
      subCategorySelected: e.target.value,
    });

    setTimeout(() => {
      this.filterProducts();
    }, 100);
  };

  filterProducts = () => {
    let products = [];

    this.state.allProduct.map((prod) => {
      if (prod.subCategory.slug == this.state.subCategorySelected) {
        products.push(prod);
      }
    });

    this.setState({
      ...this.state,
      sortedProducts: products,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
            <link rel="canonical" href={`https://voorent.com/productList/${this.state.categorySelected}`} />
        </Helmet>

        <HeaderComponent />
        <BreadCrumbs />

        <main role="main" className="container mt-5">
          <div className="row">
            <FilterComponent
              changeCategory={this.changeCategory}
              category={this.state.categorySelected}
              subCategory={this.state.subCategorySelected}
              subCategoryList={this.state.subCategoryList}
              categoryList={this.state.categoryList}
              checkSelectedCategory={this.checkSelectedCategory}
              checkSelectedSubCategory={this.checkSelectedSubCategory}
              changeSubCategory={this.changeSubCategory}
            />

            <div className="col-12 col-md-9 p-0">
              <div className="row m-2">
                <H2Title value={this.state.categorySelected.toUpperCase()} />

                {/* Mobile SubCategory Selector */}
                <div className="d-block d-sm-none">
                  <div className="col-12 mt-2 mb-2">
                    <div className="row">
                      {this.state.subCategoryList.map((
                        subCategory,
                        sIndex //
                      ) => (
                        <div
                          key={sIndex}
                          className="col-4 p-1 overflow-hidden cursor-pointer"
                        >
                          <div
                            className={
                              this.state.subCategorySelected == subCategory.slug
                                ? "form-check p-1 subcategory-mobile mt-2 subcategory-mobile-selected"
                                : "form-check p-1 subcategory-mobile mt-2"
                            }
                          >
                            <input
                              className="form-check-input d-none"
                              type="checkbox"
                              value={subCategory.slug}
                              id={subCategory.slug}
                              name="subcategory"
                            />
                            <label
                              style={{ cursor: "pointer" }}
                              className={
                                this.state.subCategorySelected ==
                                subCategory.slug
                                  ? "form-check-label ml-2 text-nowrap"
                                  : "form-check-label ml-2 text-black-50 text-nowrap"
                              }
                              htmlFor={subCategory.slug}
                            >
                              {" "}
                              {subCategory.name}{" "}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row" style={{ minHeight: "300px" }}>
                    {!this.state.isLoaded && (
                      <div className="col-12 m-auto">
                        <div className="row">
                          <div className="col-12 text-center mt-5">
                            <div
                              className="spinner-border text-primary"
                              style={{ width: "3rem", height: "3rem" }}
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                            <span className="pl-3 text-black-50 d-block">
                              Checking Inventory ..
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {this.state.isLoaded && (
                      <div className="col-12 mb-3">
                        <span className="font-weight-bold">
                          {" "}
                          {this.state.sortedProducts.length}{" "}
                        </span>
                        <span> Products found.</span>
                      </div>
                    )}

                    {this.state.isLoaded &&
                      this.state.sortedProducts.length == 0 && (
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12 text-center mt-5">
                              <img height="80px" src="./../svg/noresult.svg" />
                              <h5 className="mt-3 mb-5 text-black-50">
                                Sorry ! No Items available with following search
                                category.
                              </h5>
                            </div>
                          </div>
                        </div>
                      )}

                    {this.state.sortedProducts.map((prod, index) => (
                      <SingleProduct
                        key={index}
                        minTenure={prod.isMedical ? "1 Month" : "6 Months"}
                        product={prod}
                        styleValue={{ padding: "p-1" }}
                        isMedical={prod.isMedical}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <FooterComponent />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (currentState) => {
  return {};
};

const mapActionsToProps = (dispatch) => {
  return {
    updateBreadcumbs: (data) => {
      dispatch({ type: "breadcrumbs.update", data: data });
    },
  };
};

export default connect(mapStateToProps, mapActionsToProps)(CategoryComponent);
