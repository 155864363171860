import React, { Component } from 'react';
import MdCar from "react-ionicons/lib/MdCar";
import MdCalendar from "react-ionicons/lib/MdCalendar"

class OrderTerms extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row mt-2">
                    <div className="col-12 col-md-6">
                        <h6 className="mt-3 text-black-50" style={{ position: 'relative' }}>
                            <MdCar color="#DDD" />
                            <span className="ml-4">Free delivery in :</span>
                            <span className="ml-1 font-weight-bold">2-3 Days</span>
                        </h6>
                    </div>
                    <div className="col-12 col-md-6">
                        <h6 className="mt-3 text-black-50" style={{ position: 'relative' }}>
                            <MdCalendar color="#DDD" />
                            <span className="ml-4">Min Rental :</span>
                            <span className="ml-1 font-weight-bold">6 Months</span>
                        </h6>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default OrderTerms;