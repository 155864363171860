import React, { Component } from 'react';

class PaymentItem extends Component {
    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-6 col-md-2">
                        <span className="d-block">Payment Against</span>
                        <span className="text-black-50 d-block">{this.props.item.paymentType}</span>
                        <span className="text-black-50">{this.props.item.paymentAgainst}</span>
                    </div>
                    <div className="col-6 col-md-2">
                        <span className="d-block">Payment Mode</span>
                        <span className="text-black-50">Online</span>
                    </div>

                    <div className="col-6 col-md-2">
                        <span className="d-block">Transcation Id</span>
                        <span className="text-black-50" style={{fontSize:'14px'}}>{this.props.item.razorPayId}</span>
                    </div>

                    <div className="col-6 col-md-2">
                        <span className="d-block">Amount Billed</span>
                        <span className="text-black-50">Rs {(this.props.item.amount/100).toFixed(2)}</span>
                    </div>

                    <div className="col-6 col-md-2">
                        <span className="d-block">Transcation Date</span>
                        <span className="text-black-50">{new Date(this.props.item.createdAt).toDateString()}</span>
                    </div>

                    <div className="col-6 col-md-2">
                        <span className="d-block">Status</span>
                        <span className="text-success text-capitalize">{this.props.item.status}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentItem;