import React, { Component } from 'react';

class RentVsOwnComponent extends Component {

    render() {
        return (
            <div className="mt-3 mb-3" style={{ width: '100%' }}>
                <div className="col-12">
                <div id="carouselTest" className="carousel slide" data-ride="carousel" style={{ minHeight: '200px', padding: '100px 0px 120px 0px', border:'1px solid rgba(224, 224, 225, 0.74)', borderRadius:'8px' }}>
                    <ol className="carousel-indicators">
                        <li style={{backgroundColor: '#004980'}} data-target="#carouselTest" data-slide-to="0" className="active"></li>
                        <li style={{backgroundColor: '#004980'}} data-target="#carouselTest" data-slide-to="1" ></li>
                        <li style={{backgroundColor: '#004980'}} data-target="#carouselTest" data-slide-to="2" ></li>
                        <li style={{backgroundColor: '#004980'}} data-target="#carouselTest" data-slide-to="3" ></li>
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="row text-center">
                                <div className="col-3 offset-1">
                                    <div className="row" style={{height:'100%'}}>
                                        <div className="col-12 m-auto">
                                            <img className="lazyload" src="https://voorent.com/svg/settings.svg" width="72px" height="72px" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <span className="font-weight-bold">No Maintenance</span>
                                    <span className="d-block mt-2">Whether you want to rent furniture or appliance, you won’t have to pay extra for maintenance. Most of the things in your home may come with the responsibility of regular upkeep. But, when you rent it from Voorent, we keep your things up and working.</span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-3 offset-1">
                                    <div className="row" style={{height:'100%'}}>
                                        <div className="col-12 m-auto">
                                            <img className="lazyload" src="https://voorent.com/svg/loan.svg" width="72px" height="72px" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <span className="font-weight-bold">Zero Loan Responsibilities</span>
                                    <span className="d-block mt-2">Buying new furniture may come with a loan account. But, renting can solve your decor worries and still not put you under the stress of clearing loan installments. Further, our team at Voorent will take care of the upkeep and maintenance of the things you rent.</span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-3 offset-1">
                                    <div className="row" style={{height:'100%'}}>
                                        <div className="col-12 m-auto">
                                            <img className="lazyload" src="https://voorent.com/svg/budget.svg" width="72px" height="72px" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <span className="font-weight-bold">No Budget Limitations</span>
                                    <span className="d-block mt-2">When purchasing household products, furniture, and other necessary things, you have to face budget limitations. However, wouldn’t it be amazing to keep the budget limitations aside and just rent that favorite sofa of yours? Rent products from Voorent.</span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-3 offset-1">
                                    <div className="row" style={{height:'100%'}}>
                                        <div className="col-12 m-auto">
                                            <img className="lazyload" src="https://voorent.com/svg/support.svg" width="72px" height="72px" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <span className="font-weight-bold">Impeccable Support</span>
                                    <span className="d-block mt-2">Just in case, you face any issues after renting the products, you can reach out to us anytime. Our experienced customer executives will ensure the earliest resolution of your issues. You simply don’t have to worry about anything, contact Us whenever you need guidance.</span>
                                </div>
                            </div>
                        </div>


                    </div>
                    <a className="carousel-control-prev" href="#carouselTest" role="button" data-slide="prev">
                        <span style={{ backgroundColor: '#004980', padding: '15px', 'borderRadius': '20px' }} className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselTest" role="button" data-slide="next">
                        <span style={{ backgroundColor: '#004980', padding: '15px', 'borderRadius': '20px' }} className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                </div>
            </div>
        );
    }
}

export default RentVsOwnComponent;