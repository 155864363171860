import React, { Component } from "react";
import Slider from "react-slick";

class BannerSliderComponent extends Component {
  componentWillReceiveProps() {
    //  this.refs.slick.innerSlider.onWindowResized()
  }

  render() {
    const settings = {
      className: "center",
      centerMode: true,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      centerPadding: "40px",
      autoplay: true,
      speed: 500,
      autoplaySpeed: 2000,
      cssEase: "linear",
      pauseOnHover: true,
    };
    return (
      // <div>
      //     <Slider {...settings}>
      //         <div style={{border:'2px solid #DDD'}}>
      //             <img src="http://voorent-new.s3-website.ap-south-1.amazonaws.com/images/two.jpeg" alt="Third slide" />
      //         </div>
      //         <div style={{border:'2px solid #DDD'}}>
      //             <img src="http://voorent-new.s3-website.ap-south-1.amazonaws.com/images/four.jpeg" alt="Third slide" />
      //         </div>
      //         <div style={{border:'2px solid #DDD'}}>
      //             <img src="http://voorent-new.s3-website.ap-south-1.amazonaws.com/images/two.jpeg" alt="Third slide" />
      //         </div>
      //         <div style={{border:'2px solid #DDD'}}>
      //             <img src="http://voorent-new.s3-website.ap-south-1.amazonaws.com/images/three.jpeg" alt="Third slide" />
      //         </div>
      //     </Slider>
      // </div>

      <div
        id="carouselExampleIndicators"
        className="carousel slide m-auto"
        data-ride="carousel"
        style={{ width: "100%", paddingTop: "80px" }}
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        </ol>
        
        <div className="carousel-inner">
          {
            /*
        <div className="carousel-item active">
          
                <img
                      style={{ minHeight: "220px" }}
                      className="d-block w-100 lazyload"
                      src="https://voorent.com/voorent-storage/images/50%25+off+offer+banner+updated.jpg"
                      alt="First slide"></img>
                </div>
                <div className="carousel-item">
                <img
                      style={{ minHeight: "220px" }}
                      className="d-block w-100 lazyload"
                      src="https://voorent.com/voorent-storage/products/For+VR.jpg"
                      alt="First slide"></img>
                </div>
          */
        }
          <div className="carousel-item active">
            <img
              style={{ minHeight: "220px" }}
              className="d-block w-100 lazyload"
              src="https://voorent.com/voorent-storage/images/banner_3.png"
              alt="First slide"
            />
          </div>
          <div className="carousel-item">
            <img
                style={{ minHeight: "220px" }}
                className="d-block w-100 lazyload"
                src="https://voorent.com/voorent-storage/images/banner_2.png"
                alt="Second slide"></img>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  }
}

export default BannerSliderComponent;
