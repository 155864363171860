import React, { Component } from 'react';

class TestinomialSlider extends Component {
    render() {
        return (
            <div className="mt-3 mb-3" style={{ background: `url('https://voorent.com/voorent-storage/images/testinomial.jpeg')`, width: '100%', backgroundPosition:'10px' }}>
                <div id="carouselTestinomial" className="carousel slide testImage" data-ride="carousel" style={{ minHeight: '200px', padding: '100px 0px 120px 0px' }}>
                    <ol className="carousel-indicators">
                        <li data-target="#carouselTestinomial" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselTestinomial" data-slide-to="1"></li>
                        <li data-target="#carouselTestinomial" data-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="row text-center">
                                <div className="col-12">
                                    <img alt="test" className="lazyload" src="https://voorent.com/voorent-storage/images/rajat.png"
                                        style={{ borderRadius: '50%', border: '2px solid #f8f9fa', width: '80px', height:'80px' }} />
                                    <span className="d-block mt-3 font-weight-bold">Rajat Jain</span>
                                    <span>Gurgoan</span>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>"Great experience.. Great Service. The quality of furniture is very good and the rental rates are very affordable.<br/> Strongly Recommended."</span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <img alt="test" className="lazyload" src="https://voorent.com/voorent-storage/images/shraddha.png"
                                        style={{ borderRadius: '50%', border: '2px solid #f8f9fa', width: '80px', height:'80px' }} />
                                    <span className="d-block mt-3 font-weight-bold">Shraddha Sharma</span>
                                    <span>Gurgoan</span>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>"Just what I wanted. Verification is easy and hassle free. Delivery on time and quality of furniture is awesome.<br/> Keep it up like that"</span>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row text-center">
                                <div className="col-12">
                                    <img alt="test" className="lazyload" src="https://voorent.com/voorent-storage/images/tanuj.png"
                                        style={{ borderRadius: '50%', border: '2px solid #f8f9fa', width: '80px', height:'80px'}} />
                                    <span className="d-block mt-3 font-weight-bold">Tanuj Sharma</span>
                                    <span>Gurgoan</span>
                                </div>
                                <div className="col-8 m-auto">
                                    <span>"Their service is excellent and so is the quality of the furniture that Voorent provides. <br/> The delivery boys seated the furniture as desired."</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselTestinomial" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselTestinomial" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </div>
        );
    }
}

export default TestinomialSlider;