import keyclock from './../Config/keyclock';

const axios = require('axios');
const qs = require('querystring');

const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
}

let auth_url = `${keyclock.server_url}/realms/${keyclock.realm}/protocol/openid-connect/token`;

export function getUserToken(data) {

    let requestBody = {
        client_id : keyclock.client_id,
        grant_type : keyclock.grant_type,
        client_secret : keyclock.client_secret,
        username : data.username,
        password : data.password
    }
    
    return axios.post(auth_url, qs.stringify(requestBody), config);

}
