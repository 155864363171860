import React, { Component } from 'react';
import IosQuote from 'react-ionicons/lib/IosQuote'

class B2BReviews extends Component {

    //{this.props.item.name}
    //{this.props.item.corporate}
    //{this.props.item.description}


    render() {
        return (
            <div className="col-12 col-md-6 equal p-0">
                <div className="row mt-1 mb-1 mr-1 ml-1 equal">
                    <div className="col-12 p-4 text-center equal" style={{background:'rgb(248, 249, 250)', minHeight:'192px', borderRadius:'8px'}}>
                        <span>{this.props.item.description}</span>
                    </div>
                    <div className="col-12 text-center" style={{minHeight:'150px'}}>
                        <img className="mt-4 lazyload" style={this.props.item.class} src={this.props.item.corporateLogo} alt={this.props.item.name}/>
                        <span className="d-block font-weight-bold">{this.props.item.name}</span>
                        <span className="d-block text-black-50">{this.props.item.corporate}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default B2BReviews;