const initialState = {
    userState : {
        loggedIn : false,
        showLogin : false,
        keycloak : {},
        userInfo : {}
    },
    selectCity : {
        cityId : '',
        cityName: '',
        show : false
    },
    surveyModal : {
        show : true
    },
    userMenu : {
        show : false
    },
    order : {
        current : {},
        all : []
    },
    breadcrumbs : {
        navigation : [],
        sidebar : [],
        showSidebar : false
    },
    kyc : {
        showWebcamModal: false,
        showPanCardModal : false,
        showAddressProofModal : false,
        showJobIdModal : false,
        showBankStatementModal : false,
        showRentAgreementModal : false
    },
    coupon : {
        applied : false,
        couponCode : '',
        couponDetails : {}
    },
    reportIssue : {
        showModal : false,
        type : '',
        data : {}
    },
    orderExtend : {
        data : {},
        showExtend : false,    
    },
    updateOrderOnExtend : {
        updateOrder : false,
    },
    unpaidInvoiceModal : {
        showModal : false,
    },
    storeKeycloak : {
        data : {}
    },
    productSurvey : {
        data : {},
        showModal : false,
    },

};

const reducer = (state = initialState, action) => {
    // console.log(state.reportIssue);
    // console.log(state.orderExtend);
    
    // console.log(action);

    let tmpState = JSON.parse(JSON.stringify(state));

    switch(action.type){

        case "invoice.unpdaid.showModal.true" : {
            tmpState.unpaidInvoiceModal.showModal =true;
            break;
        }

        case "invoice.unpaid.showModal.false" : {
            tmpState.unpaidInvoiceModal.showModal  =false;
            break;
        }

        case "order.update.extend.true" : {
            tmpState.updateOrderOnExtend.updateOrder =true;
            
            break;
        }

        case "order.update.extend.false" : {
            tmpState.updateOrderOnExtend.updateOrder =false;
            break;
        }

        case "store.keycloak.localstorage" : {
            tmpState.storeKeycloak.data= action.data;
            break;
        }

        case "issue.modal.order.open" : {
            tmpState.reportIssue.showModal = true;
            tmpState.reportIssue.data = action.data;
            break;
        }

        case "issue.modal.order.close" : {
            tmpState.reportIssue.showModal = false;
            tmpState.reportIssue.issueType = "";
            tmpState.reportIssue.data = {};
            break;
        }

        case "issue.modal.product.open" : {
            tmpState.reportIssue.showModal = true;
            tmpState.reportIssue.issueType = action.data.type;
            tmpState.reportIssue.data = action.data;
            break;
        }

        case "issue.modal.invoice.open" : {
            tmpState.reportIssue.showModal = true;
            tmpState.reportIssue.issueType = action.data.type;
            tmpState.reportIssue.data = action.data;
            break;
        }

        case "issue.modal.deposit.open" : {
            tmpState.reportIssue.showModal = true;
            tmpState.reportIssue.issueType = action.data.type;
            tmpState.reportIssue.data = action.data;
            break;
        }

        case "order.modal.extend.open" :    {

            tmpState.orderExtend.data = action.data;
            tmpState.orderExtend.showExtend = true;
            break;
        }

        case "order.modal.extend.close" :    {
            tmpState.orderExtend.data = {};
            tmpState.orderExtend.showExtend = false;
            break;
        }

        case "user.auth.login" : {
            tmpState.userState.keycloak = action.data;
            tmpState.userState.loggedIn = true;
            break;
        }

        case "user.auth.logout" : {
            tmpState.userState.loggedIn = false;
            break;
        }

        case "login.update.userInfo" : {
            tmpState.userState.userInfo = action.data;
            break;
        }

        case "login.window.show" : {
            tmpState.userState.showLogin = true;
            break;
        }

        case "login.window.hide" : {
            tmpState.userState.showLogin = false;
            break;
        }

        case 'update.order.current' : {
            tmpState.order.current = action.data;
            break;
        }

        case 'breadcrumbs.update' : {
            tmpState.breadcrumbs = action.data;
            break;
        }

        case 'breadcrumbs.navigation.update' : {
            tmpState.breadcrumbs.navigation = action.data;
            break;
        }

        case 'breadcrumbs.sidebar.update' : {
            tmpState.breadcrumbs.sidebar = action.data;
            break;
        }

        case 'kyc.webcam.show' : {
            tmpState.kyc.showWebcamModal = true;
            break;
        }

        case 'kyc.webcam.hide' : {
            tmpState.kyc.showWebcamModal = false;
            break;
        }

        case 'kyc.pancard.show' : {
            tmpState.kyc.showPanCardModal = true;
            break;
        }

        case 'kyc.pancard.hide' : {
            tmpState.kyc.showPanCardModal = false;
            break;
        }

        case 'kyc.addressProof.show' : {
            tmpState.kyc.showAddressProofModal = true;
            break;
        }

        case 'kyc.addressProof.hide' : {
            tmpState.kyc.showAddressProofModal = false;
            break;
        }
        case 'kyc.jobId.show' : {
            tmpState.kyc.showJobIdModal = true;
            break;
        }

        case 'kyc.jobId.hide' : {
            tmpState.kyc.showJobIdModal = false;
            break;
        }

        case 'kyc.bankStatement.show' : {
            tmpState.kyc.showBankStatementModal = true;
            break;
        }

        case 'kyc.bankStatement.hide' : {
            tmpState.kyc.showBankStatementModal = false;
            break;
        }

        case 'kyc.rentAgreement.show' : {
            tmpState.kyc.showRentAgreementModal = true;
            break;
        }

        case 'kyc.rentAgreement.hide' : {
            tmpState.kyc.showRentAgreementModal = false;
            break;
        }

        case 'user.menu.show' : {
            tmpState.userMenu.show = true;
            break;
        }

        case 'user.menu.hide' : {
            tmpState.userMenu.show = false;
            break;
        }

        case 'product.survey.show' : {
            tmpState.productSurvey.showModal = true;
            tmpState.productSurvey.data = action.data;
            break;
        }

        case 'product.survey.hide' : {
            tmpState.productSurvey.showModal = false;
            tmpState.productSurvey.data = {};
            break;
        }

        case 'user.city.show' : {
            tmpState.selectCity.show = true;
            break;
        }

        case 'user.city.hide' : {
            tmpState.selectCity.show = false;
            break;
        }

        case 'survey.hide' : {
            tmpState.surveyModal.show = false;
            break;
        }
        case 'coupon.apply' : {
            tmpState.coupon.applied = true;
            tmpState.coupon.couponCode = action.data.couponCode;
            tmpState.coupon.couponDetails = action.data.couponDetails;
            break;
        }

        case 'coupon.remove' : {
            tmpState.coupon.applied = false;
            tmpState.coupon.couponCode = '';
            tmpState.coupon.couponDetails = {};
            break;
        }

        default : return tmpState;
    }

    return {
        ...tmpState
    }

};

export default reducer;