import React, { Component } from 'react';
import H2Title from './../../Generic/Element/Heading';
import * as service from './../../../Service/service';
import IssueElement from './IssueElement';

class IssuesComponent extends Component {
    
    state = {
        issueList: [],
        isLoaded : false
    }

    getAllIssues = () => {

        service.showAllIssues().then((response) => {
            this.setState({
                ...this.state,
                issueList: response.data,
                isLoaded : true
            })

        }, (error) => {
            console.log(error);
        });
    }

    componentDidMount() {
        this.getAllIssues();
    }
    
    render() { 
        
        return ( 

            <div className="row m-3">
                <div className="col-12">
                    <H2Title value="Issues" />

                    <div className="row element-border mt-3 mb-3 mr-1 ml-1">
                    {
                                            !this.state.isLoaded && <div className="col-12 m-auto">
                                                <div className="row">
                                                    <div className="col-12 text-center mt-5">
                                                        <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                        <span className="pl-3 text-black-50 d-block" >Checking Inventory ..</span>
                                                    </div>
                                                </div>
                                            </div>
                    }
                    
                        {
                            this.state.isLoaded && this.state.issueList.length === 0 && (
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 text-center mt-5">
                                            <img height="80px" src="./svg/issue.svg" />
                                            <h5 className="mt-3 mb-5">No issue found.</h5>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            this.state.issueList.length !== 0 && this.state.issueList.map((issue, iIndex) => (
                                <React.Fragment key={iIndex}>
                                    <IssueElement  issueList={issue} />
                                    {iIndex === this.state.issueList.length - 1 ? '' : (<hr />)}
                                </React.Fragment>
                            ))
                        }
                    </div>
                </div>
            </div>
         );
    }
}
 
export default IssuesComponent;