import React, { Component } from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import OrderTerms from './OrderTerms';

class ImageCrousel extends Component {
    constructor(props) {
        super(props);
    
        this.settings = {
          dots: true,
          infinite: true,
          fade: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
        };
      }
    
    render() {
        return (
            // <Carousel showIndicators={false}>
            //      <div>
            //      <img  alt={"NA"} src={this.props.featuredImage} />
            //      {this.props.status==='Out of Stock' && (<span style={{
            //         background: 'white',
            //         color: 'red',
            //         fontSize:'20px',
            //         fontWeight:'600',
            //         padding:' 5px 10px',
            //         position: 'absolute',
            //         right: '50px',
            //         top: '10px'}}>Out Of Stock</span>)}
            //     </div>
            //     {
            //         this.props.images.map((img, iIndex) => (
            //             <div key={iIndex}>
            //                 <img alt={img.altText} src={img.imageURL} />
            //             </div>
            //         ))
            //     }
            // </Carousel>
            <div className='carousal-container'>
                <Slider {...this.settings}>
                    <div>
                    <img  alt={"NA"} src={this.props.featuredImage} />
                    {this.props.status==='Out of Stock' && (<span style={{
                        background: 'white',
                        color: 'red',
                        fontSize:'20px',
                        fontWeight:'600',
                        padding:' 5px 10px',
                        position: 'absolute',
                        right: '50px',
                        top: '10px'}}>Out Of Stock</span>)}
                    </div>
                    {
                        this.props.images.map((img, iIndex) => (
                            <div key={iIndex} className="slide">
                                <img alt={img.altText} src={img.imageURL} />
                            </div>
                        ))
                    }
                </Slider>
                <div style={{ padding: '4%' }}>
                    <OrderTerms />
                </div>
            </div>
        );
    }
}

export default ImageCrousel;