import React, { Component } from 'react';

import LoginComponent from '../Login/Login/LoginComponent';

import HeaderComponent from '../../Generic/Header/HeaderComponent';
import FooterComponent from '../../Generic/Footer/FooterComponent';
import { connect } from 'react-redux';
import BreadCrumbs from './../../Generic/BreadCrumbs/BreadCrumbs';

class LoginPageComponent extends Component {

    state = {
        currentScreen: 'login',
        userData: {
            username: '',
            password: ''
        },
    }


    updateState = (state) => {
        this.setState({
            ...state
        });
    };


    changeCurrentScreen = (screen) => {
        this.setState({
            ...this.state,
            currentScreen: screen
        });
    }

    componentDidMount = () => {
        this.props.selectCityClose();
        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: './' }, { name: 'Login', link: '#' }],
            showSidebar: false,
            sidebar: []
        });
    }

    render() {
        return (
            <React.Fragment>

                <HeaderComponent />
                <BreadCrumbs />
                <main role="main" className="container m-auto">
                    <div className="row mt-5" style={{ height: '100%' }}>
                        <div className="col-5 col-md-5 d-none d-md-block" style={{ minHeight: '100%', backgroundPosition: '50% 50%', backgroundImage: 'url("https://images.unsplash.com/photo-1507904953637-96429a46671a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60")', backgroundSize: '100% 120%', backgroundRepeat: 'no-repeat' }}>
                        </div>

                        <div className="col-12 col-md-7 p-5 mb-auto mt-auto position-relative" style={{ minHeight: '450px' }}>

                            <LoginComponent isGeneralLogin={true} state={this.state} updateState={this.updateState} changeScreen={this.changeCurrentScreen} />
                        </div>
                    </div>
                </main>
                <FooterComponent />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        },
        selectCityClose: () => {
            dispatch({ type: 'user.city.hide' });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(LoginPageComponent);