import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import { connect } from 'react-redux';
import H2Title from "../Element/Heading";
import validator from 'validator';

import * as service from '../../../Service/service';


class ProductSurveyModal extends Component {

    state = {
        isUpdated:false,
        mobile:'',
        name:'',
        email:'',
    }


    onInput=(e)=>{
        this.setState({
            ...this.state,
            [e.target.name] : e.target.value
        }, () => {
            console.log(this.state);
        });
     }

     submitData=()=>{
         this.setState({
             updating:true
         })
        if(validator.isMobilePhone(this.state.mobile,['en-IN']) && validator.isEmail(this.state.email) && this.state.name.length >= 4 ){
                service.submitProductSurvey({
                    customerName: this.state.name,
                    email: this.state.email,
                    contact: this.state.mobile,
                    productName : this.props.productSurvey.data.productName,
                    priceRange : this.props.productSurvey.data.priceRange
                }).then((response) => {

                    this.setState({
                        ...this.state,
                        isUpdated: true,
                        message:'Thanks for the interest. Our team will contact you !',
                        updating : false,
                        mobile:'',
                        name:'',
                        email:'',
                        styling : 'alert alert-success alert-dismissible fade show'
                    });


                }, (error) => {
                    this.setState({
                        message:'Something went wrong!',
                        isUpdated:true,
                        updating : false,
                        styling : 'alert alert-danger alert-dismissible fade show'
                    })
                }
                );
        }
        else{
            this.setState({
                message:'Invalid input !',
                isUpdated:true,
                updating : false,
                styling : 'alert alert-danger alert-dismissible fade show'
            })
        }
     }

    render() {
        return (
            <Modal visible={this.props.productSurvey.showModal} onClickBackdrop={this.props.closeModal}>
                <div className="row" style={{height:'100%'}}>
                        <div className="col-5 col-md-6 d-none d-md-block" style={{minHeight:'100%',overflow: 'hidden', background:'#eff2f7'}}>
                                <img className="lazyload" src="https://voorent.com/voorent-storage/images/login.d814adb7.png" alt="" style={{marginTop: '30%'}} />
                        </div>

                <div className="col-12 col-md-6 p-5 mb-auto mt-auto position-relative" style={{minHeight:'450px'}}>
                <div className="ml-2">
                <H2Title  value={this.props.productSurvey.data.productName} />
                </div>
                {
                                this.state.isUpdated && (
                                    <div className="col-12">
                                        <div className={this.state.styling} role="alert">
                                            {this.state.message}.
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                            {console.log(this.props.productSurvey)}
 
                    <div className="col-12">
                        <form>
                            <div className="mt-2">
                                <label htmlFor="name" className="font-weight-bold">Name</label>
                                <input name='name' value={this.state.name} onChange={this.onInput} className="form-control" id="name" placeholder="Enter name"/>
                            </div>
                            <div className="mt-2">
                                <label htmlFor="email" className="font-weight-bold">Email</label>
                                <input name='email' value={this.state.email} onChange={this.onInput} className="form-control" id="email" placeholder="Enter email"/>
                            </div>
                            <div className="mt-2">
                                <label htmlFor="Mobile" className="font-weight-bold">Mobile</label>
                                <input onBlur={this.validateMobile} name='mobile' value={this.state.mobile} onChange={this.onInput}  className="form-control" id="Mobile" placeholder="Enter mobile"/>
                            </div>
                            
                        </form>
                        <button type="button" className="btn btn-success mt-4 col-12" disabled={this.state.updating} onClick={this.submitData}>
                        Submit
                        </button>
                        <button type="button" className="btn btn-primary mt-2 col-12" onClick={this.props.closeModal}>
                            Close
                        </button>
                        
                    </div>
                    

                </div>
                
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        productSurvey : currentState.productSurvey
    };
};


const mapActionsToProps = (dispatch) => {
    return {
        closeModal: () => {
            dispatch({ type: 'product.survey.hide' })
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(ProductSurveyModal);