import React, { Component } from 'react';
import H2Title from './../../Generic/Element/Heading';
import MdDoneAll from 'react-ionicons/lib/MdDoneAll';

import * as service from './../../../Service/service';

import PaymentList from './PaymentList';

class PaymentComponent extends Component {

    state = {
        payment: {},
        info: {
            autoPayments: false
        },
        isLoaded : false
    }

    listAllPayments = () => {
        service.listPayment().then((response) => {

            let paymentList = {};

            response.data.length != 0 && response.data.map((payment) => {
                let month = new Date(payment.createdAt).toDateString().split(" ")[1];
                let year = new Date(payment.createdAt).toDateString().split(" ")[3];
                let final = `${month}-${year}`

                if (typeof paymentList[final] == "undefined") {
                    paymentList[final] = [];
                    paymentList[final].push(payment);
                } else {
                    paymentList[final].push(payment);
                }
            })

            this.setState({
                ...this.state,
                payment: paymentList,
                isLoaded : true
            });

        }, (error) => {
            console.log(error);
        });
    }

    componentDidMount() {
        this.listAllPayments();
    }

    render() {
        return (
            <React.Fragment>
                <div className="row mt-3 mb-3">
                    <div className="col-12">
                        <H2Title value="Payments" />

                        {/*  Should enable with auto-payments integration
                        
                        <div className="row mt-3">

                            <div className="col-12 col-md-6 mt-2" style={{ minHeight: '250px' }}>
                                <div className="row mr-1 ml-1 p-2 element-border" style={{ height: '100%' }}>
                                    <div className="col-12">
                                        <span className="font-weight-bold">Setup Auto Payements</span>
                                    </div>
                                    <div className="col-12 mt-1 mb-5">
                                        <span className="d-block"> <MdDoneAll /> Get freedom from paying bills monthly.</span>
                                        <span className="d-block"> <MdDoneAll /> Avoid late fee charges.</span>
                                        <span className="d-block"> <MdDoneAll /> Get a discount of 4% on monthly bill.</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mt-2" style={{ minHeight: '250px' }}>
                                <div className="row ml-1 mr-1 element-border p-2" style={{ height: '100%' }}>
                                    <div className="col-8">
                                        <span className="font-weight-bold">Select Auto Payment Method</span>
                                    </div>
                                    <div className="col-4 text-right">
                                        <span className="text-danger">Pending</span>
                                    </div>
                                    <div className="col-12">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-8 text-center mt-5 mb-5">
                                                <button type="button" className="btn btn-primary btn-block disabled">Net Banking</button>
                                                <button type="button" className="btn btn-secondary btn-block disabled">Debit Card</button>
                                                <button type="button" className="btn btn-success btn-block disabled">Credit Card</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-2 text-center">
                                        <span className="text-black-50" style={{ fontSize: '12px' }}>
                                            Voorent will automatically charge the invoice generated after 1 day of generation.
                                    </span>
                                    </div>
                                </div>
                            </div>

                        </div> */}

                        <div className="row element-border mt-3 mr-1 ml-1">
                            <div className="col-12 p-3">
                                {
                                    !this.state.isLoaded && (<div className="col-12 m-auto" style={{minHeight:'250px'}}>
                                        <div className="row">
                                            <div className="col-12 text-center mt-5">
                                                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <span className="pl-3 text-black-50 d-block" >Checking backend . . </span>
                                            </div>
                                        </div>
                                    </div>)
                                }

                                {
                                    this.state.isLoaded && Object.keys(this.state.payment).length == 0 && (
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 text-center mt-5">
                                                    <img height="150px" src="./svg/payment.svg" />
                                                    <h5 className="mt-3 mb-5">No payments done till now, Your payments will be shown here once you pay something to us.</h5>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    Object.keys(this.state.payment).map((paymentIndex, pIndex) => (
                                        <PaymentList payment={this.state.payment[paymentIndex]} month={paymentIndex} key={pIndex} />
                                    ))
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default PaymentComponent;