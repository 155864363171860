import React, { Component } from 'react';

class EnquiryElement extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="col-12 p-3">
            <div className="row">
                <div className="col-8 col-md-9">
                    <div className="row">
                        <div className="col-12">
                            <span className="font-weight-bold">Enquiry Number : </span>
                            <span className="text-uppercase ">{this.props.enquiryList.orderNumber}</span>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12">
                            <span className="text-danger d-block" >Voorent Support :</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-3 ">
                    <span className="font-weight-bold d-block">Status</span>
                    <span className="text-success">{this.props.enquiryList.orderStatus}</span>
                </div>

            </div>
        </div>
         );
    }
}
 
export default EnquiryElement;