import * as endpoint from './endpoints';
import Axios from 'axios';

export function getServiceArea(){
    return Axios.get(endpoint.getEndpoint("PRODUCT","serviceArea"));
}

export function getTrendingProducts(){
    return Axios.get(endpoint.getEndpoint("PRODUCT", "trending"));
}

export function getPackages(){
    return Axios.get(endpoint.getEndpoint("PRODUCT", "packages"));
}

export function getPopularProducts(){
    return Axios.get(endpoint.getEndpoint("PRODUCT", "popular"));
}

export function getAllCategories(){
    return Axios.get(endpoint.getEndpoint("PRODUCT", "category"));
}

export function getSubCategories(category){
    return Axios.get(`${endpoint.getEndpoint("PRODUCT", "categoryBase")}${category}`);
}

export function getProductByCategory(category){
    return Axios.get(`${endpoint.getEndpoint("PRODUCT", "productByCategory")}/${category}${endpoint.getLimit()}`);
}

export function getSingleProduct(productSlug){
    return Axios.get(`${endpoint.getEndpoint("PRODUCT", "viewProduct")}/${productSlug}`);
}

export function checkInventory(productId){
    return Axios.get(`${endpoint.getEndpoint("PRODUCT", "checkInventory")}/${productId}/${localStorage.cityId}`)
}

export function addToCart(cart){
    return Axios.post(`${endpoint.getEndpoint("ORDER", "addToCart")}`, cart);
}

export function checkCart(){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "checkCart")}`);
}

export function cartProductInfo(slugs){
    return Axios.post(`${endpoint.getEndpoint("PRODUCT", "cartProductInfo")}`, slugs);
}

export function verifyPinCode(pincode){
    return Axios.get(`${endpoint.getEndpoint("PRODUCT", "verifyPinCode")}/${pincode}`);
}

export function addAddress(address){
    return Axios.post(`${endpoint.getEndpoint("USER", "addAddress")}`, address);
}

export function allAddress(){
    return Axios.get(`${endpoint.getEndpoint("USER", "allAddress")}`);
}

export function placeOrder(orderData){
    return Axios.post(`${endpoint.getEndpoint("ORDER", "placeOrder")}`, orderData);
}

export function listOrders(orderData){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "viewAllOrders")}/${endpoint.getLimit()}`);
}

export function deleteAddress(id){
    return Axios.get(`${endpoint.getEndpoint("USER", "deleteAddress")}/${id}`);
}

export function listDeposit(){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "listDeposit")}`);
}

export function listPayment(){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "listPayment")}`);
}

export function listInvoice(){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "listInvoices")}`);
}

export function checkUsername(username){
    return Axios.get(`${endpoint.getEndpoint("USER", "checkUsername")}/${username}`);
}

export function checkEmail(email){
    return Axios.get(`${endpoint.getEndpoint("USER", "checkEmail")}/${email}`);
}

export function registerUser(userObj){
    return Axios.post(`${endpoint.getEndpoint("USER", "registerUser")}`, userObj);
}

export function deleteCartItem(productSlug){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "deleteCartItem")}/${productSlug}`);
}

export function uploadDocument(obj){
    const config = {
        headers: {
            "content-type": "multipart/form-data"
        }
    };
    return Axios.post(`${endpoint.getEndpoint("USER", "uploadDocument")}`, obj, config);
}

export function listDocuments(){
    return Axios.get(`${endpoint.getEndpoint("USER", "allDocuments")}`);
}

export function getUserInfo(){
    return Axios.get(`${endpoint.getEndpoint("USER", "userInfo")}`);
}

export function getUserOtp(contact){
    return Axios.get(`${endpoint.getEndpoint("USER", "userOtp")}/${contact}`);
}

export function getRazorpayOrderId(orderNumber){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "razorPayOrderId")}/${orderNumber}`);
}

export function getRazorpayOrderIdInvoice(invoiceNumber){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "razorPayInvoice")}/${invoiceNumber}`);
}

export function updatePayment(obj){
    return Axios.post(`${endpoint.getEndpoint("ORDER", "updatePayment")}`, obj);
}

export function updatePaymentInvoice(obj){
    return Axios.post(`${endpoint.getEndpoint("ORDER", "updatePaymentInvoice")}`, obj);
}

export function deleteDocument(documentId){
    return Axios.get(`${endpoint.getEndpoint("USER", "deleteDocument")}/${(documentId).split("/")[1]}`);
}

export function manageQuantity(data){
    return Axios.post(`${endpoint.getEndpoint("ORDER", "adjustQuantity")}`, data);
}

export function orderInfo(data){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "orderInfo")}`);
}

export function changePassword(data){
    return Axios.post(`${endpoint.getEndpoint("USER", "changePassword")}`, data);
}

export function cancelOrder(orderNumber){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "cancelOrder")}/${orderNumber}`);
}

export function passwordReset(obj){
    return Axios.post(`${endpoint.getEndpoint("USER", "passwordReset")}`, obj);
}

export function passwordResetMail(email){
    return Axios.get(`${endpoint.getEndpoint("USER", "passwordResetMail")}/${email}`);
}

export function resetPasswordMailer(id){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "resetPasswordMailer")}/${id}`);
}

export function checkCoupon(couponCode){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "checkCoupon")}/${couponCode}`);
}

export function applyCoupon(couponCode){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "applyCoupon")}/${couponCode}`);
}

export function bulkInvoicePayment(type){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "bulkInvoicePayment")}/${type}`);
}

export function printInvoice(invoice){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "printInvoice")}/${invoice}`);
}

export function checkAddress(addressId){
    return Axios.get(`${endpoint.getEndpoint("USER", "checkAddress")}/${addressId}`);
}

export function orderInfoByOrderNumber(orderNumber){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "orderInfo")}/${orderNumber}`);
}

export function orderMail(orderNumber){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "orderMail")}/${orderNumber}`);
}

export function getReasons(type){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "getReasons")}/${type}`);
}

export function reportIssue(data){
    return Axios.post(`${endpoint.getEndpoint("ORDER", "reportIssue")}`, data);
}

export function showAllIssues(){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "showAllIssues")}`);
}

export function orderExtend(data){
    return Axios.post(`${endpoint.getEndpoint("ORDER", "orderExtend")}`,data);
}

export function getExtendOrder(){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "getExtendOrder")}`);
}

export function searchItems(keyword){
    return Axios.get(`${endpoint.getEndpoint("PRODUCT", "searchItems")}/${keyword}`);
}

export function recommendedItems(keyword){
    return Axios.get(`${endpoint.getEndpoint("PRODUCT", "recommendedItems")}/${keyword}`);
}

export function getRazorpayExtendId(orderNumber){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "razorPayExtendId")}/${orderNumber}`);
}

export function updatePaymentExtend(obj){
    return Axios.post(`${endpoint.getEndpoint("ORDER", "updatePaymentExtend")}`, obj);
}

export function invoiceIsPaid(orderNumber){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "invoiceIsPaid")}/${orderNumber}`);
}
export function razorPayKeys(){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "razorPayKeys")}`);
}
export function showAllEnquiry(){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "showAllEnquiry")}`);
}
export function submitSurvey(obj){
    return Axios.post(`${endpoint.getEndpoint("USER", "submitSurvey")}`, obj);
}
export function submitProductSurvey(obj){
    return Axios.post(`${endpoint.getEndpoint("PRODUCT", "submitProductSurvey")}`, obj);
}
export function getRazorpaySubscriptionId(orderNumber){
    return Axios.get(`${endpoint.getEndpoint("ORDER", "razorPaySubscriptionId")}/${orderNumber}`);
}
export function getAllNewProducts(category,type){
    return Axios.get(`${endpoint.getEndpoint("PRODUCT", "getAllNewProducts")}/${type}?search=${category}`);
}
export function getNewProductsCategory(){
    return Axios.get(`${endpoint.getEndpoint("PRODUCT", "getNewProductsCategory")}`);
}
export function getNewProductsSubCategory(){
    return Axios.get(`${endpoint.getEndpoint("PRODUCT", "getNewProductsSubCategory")}`);
}