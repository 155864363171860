import React, { Component } from 'react';

class Rating extends Component {

    /* 
     * rating { value , reviewCount, rating }
     */

    style = {
        starStyle: {
            fontSize: {
                value : '16px',
                important : true
            },
            color: '#f8ad47 !important'
        },
        infoStyle: {
            display: 'inline-block',
            position: 'relative',
            top: '-5px'
        }
    }

    render() {
        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-8">
                        <div style={{ display: 'block' }}>
                            {
                                Array.from({ length: 5 }).map(() => (
                                    <ion-icon name="star" style={this.style.starStyle}></ion-icon>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-4">
                        <div style={this.style.infoStyle}>
                            <span class="text-bold">{this.props.value}</span>
                            <span class="text-black-50">( {this.props.reviewCount} )</span>
                        </div>
                    </div>
                </div>



            </React.Fragment>
        );
    }
}

export default Rating;