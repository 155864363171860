var APPLICATION_URI = window.location.origin;
var dev_uri = "https://v2.voorent.com";
var prod_uri = "https://v2.voorent.com";

var MS = {};

if (window.location.hostname.indexOf("voorent") != -1) {
  MS = {
    PRODUCT: `${prod_uri}/product`,
    ORDER: `${prod_uri}/order`,
    USER: `${prod_uri}/user`,
    MAILER: `${prod_uri}/mailer`,
  };
} else {
  MS = {
    PRODUCT: `${prod_uri}/product`,
    ORDER: `${prod_uri}/order`,
    USER: `${prod_uri}/user`,
    MAILER: `${prod_uri}/mailer`,
  };
}

const ENDPOINTS = {
  serviceArea: "/serviceArea/all",
  trending: "/product/trending",
  packages: "/product/packages",
  popular: "/product/mostViewed",
  categoryBase: "/category/",
  category: "/category/all",
  productByCategory: "/product/all",
  viewProduct: "/product/view/",
  limit: "?limit=100&offset=0",
  checkInventory: "/inventory/checkAvailability",
  addToCart: "/cart/add",
  checkCart: "/cart/check",
  cartProductInfo: "/product/cartProductInfo",
  verifyPinCode: "/pincode/verify",
  addAddress: "/address/new",
  allAddress: "/address/all",
  placeOrder: "/order/place",
  viewAllOrders: "/order/all",
  deleteAddress: "/address/delete",
  listDeposit: "/deposit/all",
  listPayment: "/payment/all",
  listInvoices: "/invoice/all",
  checkUsername: "/user/checkUsername",
  checkEmail: "/user/checkEmail",
  registerUser: "/user/add",
  deleteCartItem: "/cart/delete",
  uploadDocument: "/kyc/uploadDocument",
  allDocuments: "/kyc/all",
  userInfo: "/user/userInfo",
  userOtp: "/user/userOtp",
  razorPayOrderId: "/order/razorpay/orderId",
  razorPaySubscriptionId: "/order/razorpay/subscriptionId",
  updatePayment: "/order/razorpay/upadateOrder",
  updatePaymentInvoice: "/invoice/razorpay/upadateOrder",
  deleteDocument: "/kyc/delete",
  adjustQuantity: "/cart/manageQuantity",
  orderInfo: "/order/info",
  razorPayInvoice: "/invoice/razorpay/invoice",
  cancelOrder: "/order/cancel",
  passwordReset: "/user/password/update",
  passwordResetMail: "/user/password/reset",
  resetPasswordMailer: "/user/mailer/password/reset",
  checkCoupon: "/promotion/check",
  applyCoupon: "/cart/applyCoupon",
  bulkInvoicePayment: "/invoice/razorpay/bulk",
  printInvoice: "/invoice/print",
  checkAddress: "/address/check",
  orderMail: "/order/mailer",
  getReasons: "/issue/reasons",
  reportIssue: "/issue/save",
  showAllIssues: "/issue/getAll",
  orderExtend: "/order/extend",
  getExtendOrder: "/order/extend/getAll",
  changePassword: "/user/changePassword",
  searchItems: "/product/search",
  recommendedItems: "/product/get/recommendation",
  razorPayExtendId: "/order/razorpay/extend",
  updatePaymentExtend: "/order/razorpay/updateExtend",
  invoiceIsPaid: "/invoice/orderNumber",
  razorPayKeys: "/order/razorpay/keys",
  showAllEnquiry: "/order/getAll/enquiry",
  submitSurvey: "/user/submitSurvey",
  submitProductSurvey: "/new/products/submitProductSurvey",
  getAllNewProducts: "/new/products",
  getNewProductsCategory: "/new/product/category",
  getNewProductsSubCategory: "/new/product/subcategory"
};

export function getRoute(key) {
  return `${APPLICATION_URI}/${localStorage.city}/${key}`;
}

export function getRoot(key) {
  return `${APPLICATION_URI}/${key}`;
}

export function getEndpoint(microservice, key) {
  return `${MS[microservice]}${ENDPOINTS[key]}`;
}

export function getLimit() {
  return `${ENDPOINTS["limit"]}`;
}
