import React, { Component } from 'react';

class VRComponent extends Component {
    state = {  }
    render() { 
        return ( 
            <React.Fragment>
                <iframe title="Iframe" style={{height:'100%',width:'100%',margin:0,position:'absolute'}} src="https://storage.net-fs.com/hosting/6832660/14/">
                </iframe>
            </React.Fragment>
         );
    }
}
 
export default VRComponent;