import React, { Component } from 'react';

class Blog8 extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="col-12">
                    <span>When it comes to the bedroom, beds play a crucial role in making the room look more aesthetic which makes the selection of wood more important, a wood that is a perfect combination of durability and style. We bring you an informative blog on “Types of wood used for making beds” which can help you out in the selection of the wood which can enhance the vibe of your room.</span><br /><br></br>
                    <h4><b>Mahogany wood:</b></h4><div> Mahogany is known for its rich, reddish-brown colour and long-lasting durability. This hardwood is not only visually striking but also exceptionally durable. Mahogany beds stand out as an embodiment of classic beauty and craftsmanship. If you are looking for traditional and luxurious aesthetics, a mahogany bed might be the perfect choice for you.</div><br />
                    <h4><b>Maple wood:</b></h4><div>Maple wood is creamy white in colour which is not naturally water resistant due to which it is not preferred for the outdoor furniture but most of the people prefer maple wood for the interior like flooring, beds and other furniture. Maple wood is very hard and comes in the heavy wood category.</div><br />
                    <h4><b>Pine wood:</b></h4><div>Pine trees are tall and straight with small branches, pine wood belongs to the softwood group of the trees hence an ideal choice for the furniture in home. Compared to other wood they are less prone to shrinkage and swelling in humid weather. Pine wood comes in two different colours i.e. light brown and lighter yellowish white.</div><br />
                    <h4><b>Teak wood:</b></h4><div>Known for its exotic beauty and durability, teak is a tropical hardwood ideal for bed frames. With a golden-brown colour that deepens over time and a distinct grain pattern, teak beds bring a touch of luxury to the bedroom. Teak's resistance to water and moisture makes it perfect for humid environments, ensuring longevity and a stunning aesthetic for years and can also be a perfect option for outdoor furniture.</div><br />
                    <h4><b>Walnut wood:</b></h4><div>Walnut wood is always opted for its durability. Walnut wood is hypoallergenic because it doesn't contain sap or resin, two major allergies present in other species of wood. This makes it an excellent option for those who are allergic to specific components or have sensitivity to them. For those looking for a classy bed that stands out, wood is a popular option because of its inherent brilliance and durability. Walnut's adaptability is further enhanced by its ability to blend in with both traditional and modern decor styles.</div>
                    <h4><b>Cedar wood:</b></h4><div>Beds made of cedar wood offer a special blend of strength and aromatic appeal. Cedar's unique smell fills the bedroom with a natural fragrance, while its rich reddish-brown colour and attractive grain patterns provide visual appeal. Because it is naturally resistant to insects and deterioration, cedar is a great material for beds that will survive for a long time.</div><br />
                    <h4><b>Birchwood:</b></h4><div>Birch wood beds are reasonably priced and have a clean, stylish appearance. Birch is a popular choice because of its modern look due to its light shade and fine grain. For those on a budget, birch is still an affordable choice even though it's softer than some hardwoods. Because of its smooth surface, wood may be finished in a variety of ways to suit your own aesthetic.</div><br />
                    <h4><b>Ash wood:</b></h4><div>Bedroom furniture appears lighter and more spacious due to the unique grain patterns and light colour of ash wood. Its light colour makes it simple to fit into different design styles, and its strength and longevity make it a dependable option for bed frames. Ash wood can be your best option if you want a bed that gives your bedroom a bright and spacious feel.</div><br /><br></br>
                    <div>Above are some wood types which can fit to your room furniture, if you don’t want to purchase new furniture then you can go for renting, they make furniture from fine premium quality wood which can fit to any room design and at a very pocket friendly rate. Voorent is one of the platforms which offers you a wide range of furniture at very affordable rates in Gurgaon and delhi. You can directly visit the site www.voorent.com and rent the furniture.</div><br></br>
                    <div>For more informative blogs visit: <a href="https://voorent.com">https://voorent.com</a></div>
                </div>
            </React.Fragment>
        );
    }
}

export default Blog8;