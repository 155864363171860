import React, { Component } from 'react';

import * as service from './../../../../Service/service';
import { connect } from 'react-redux';

class Coupon extends Component {

    state = {
        couponApplied: false,
        validCoupon: false,
        disable: false,
        couponName: 'HOLI10',
        userCoupon: {
            title: ''
        },
        applyStatus: 'badge badge-danger',
        applyMessage: 'Invalid Coupon'
    }

    validateCoupon = () => {
        service.checkCoupon(this.state.userCoupon).then(response => {

            if (response.data.length != 0) {

                this.setState({
                    ...this.state,
                    applyStatus: 'badge badge-success',
                    applyMessage: 'Coupon Applied',
                    couponApplied: true,
                    validCoupon: true,
                    disable: true,
                    userCoupon: response.data
                });

                this.props.applyCoupon({
                    applied: true,
                    couponCode : response.data.code,
                    couponDetails : response.data
                });

                this.props.calculateBilling();

            } else {

                this.setState({
                    ...this.state,
                    applyStatus: 'badge badge-danger',
                    applyMessage: 'Invalid Coupon',
                    couponApplied: true,
                    validCoupon: false,
                    disable: false,
                    userCoupon: {}
                });

                this.props.removeCoupon();
                this.props.calculateBilling();
            }

        }, error => {
            console.log(error);
        });
    }


    addCoupon = (e) => {
        this.setState({
            ...this.state,
            userCoupon: e.target.value
        })
    }

    render() {
        return (
            <div className="row mb-4 ml-1 mr-1 mt-4">
                <div className="col-12 pb-3 pt-3" style={{ background: 'rgb(248, 249, 250)', borderRadius: '2px' }} >
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-4">
                            <div className="row">
                                <div className="col-8 text-right">
                                    <input onChange={this.addCoupon} type="email" style={{ height: '31px' }} className="form-control" placeholder="Coupon code" />
                                    <span className="mr-1">{this.state.userCoupon.title}</span>
                                    <span style={{ display: this.state.couponApplied ? 'inline-block' : 'none' }} className={this.state.applyStatus}>{this.state.applyMessage}</span>
                                </div>
                                <div className="col-4">
                                    <button onClick={this.validateCoupon} type="button" className="btn btn-primary btn-block btn-sm">Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 offset-md-6 text-right mt-2">
                            {/* <a className="disabled" href="#">View Available Promos</a> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (currentState) => {
    return {
        coupon : currentState.coupon
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        applyCoupon: (data) => {
            dispatch({ type: 'coupon.apply', data: data });
        },
        removeCoupon : (data) => {
            dispatch({type : 'coupon.remove'});
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(Coupon);