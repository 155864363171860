import React, { Component } from 'react';

class AboutUs extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="col-12 mb-5 p-0">
                    <div className="row">
                        {/* <div className="col-12 text-center">
                            <h4 className="font-weight-bold mb-4">Our Team</h4>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <span className="d-block">
                                Haven't you wished for furniture and appliances to magically appear in your apartment or just hoped for these costly things to show up in a flea market at manageable rates?
                            </span>
                            <span className="d-block mt-3">
                                Voorent was started with a vision of providing exactly that. We are a group of professionals who think that if you want classy furniture in your house, then you shouldn't settle for old tainted one. We provide appliances and furniture on rent for your home. Just pay us a monthly rent and keep up your home decor elegant and stylish.
                            </span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AboutUs;