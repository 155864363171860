import React, { Component } from 'react';
import * as service from './../../../../Service/service';

class CustomerDetails extends Component {

    state = {
        addrress: {
            name: '',
            contactNo: '',
            address1: '',
            houseNo: '',
            pincode: '',
            city: '',
            differentBilling: '',
            addressType: ''
        },
        activeForDelivery: true,
        allAddress: [],
        addNewAddress: false,
        selectedAddressId: 0,
        selectedAddress: {},
        showError:false,
        message:'',
    };

    addNewAddressFlag = () => {
        this.setState({
            ...this.state,
            addNewAddress: !this.state.addNewAddress,
            selectedAddressId: 0,
            addrress:{
                pincode:''
            },
            showError:false,
            message : ''
        });
    }

    checkSelectedAddress=(id ,pin)=>{
        this.setState({
            showError:false,
            message : ''
        })
        this.checkPincode(pin,"select address",id)
    }

    selectAddress = (id , isActive) => {
        var selectdAddress={};
        if(isActive === true){
        this.state.allAddress.map((add) => {
            if (add.Id == id ) {
                selectdAddress = add;  
            }
           
        })
        this.setState({
            ...this.state,
            selectedAddressId: id,
            selectedAddress: selectdAddress
        })
    }
    else{
        this.setState({
            ...this.state,
            selectedAddressId: 0,
            activeForDelivery:true,
            showError:true,
            message : 'This area is unservicable!'
        })
    }


    }

    allAddress = () => {
        service.allAddress().then((response) => {
            if (response.data.length != 0) {
                this.setState({
                    ...this.state,
                    allAddress: response.data,
                    addNewAddress: false,
                    message : '',
                    showError:false,
                });
            } else {
                this.setState({
                    ...this.state,
                    addNewAddress: true,
                    
                });
            }
        }, (error) => {
            console.log(error);
        });
    }

    addAddress = () => {
        console.log(this.state.addrress)
        if(this.state.activeForDelivery===true && this.state.addrress.contactNo.length===10 && this.state.addrress.houseNo.length!==0 && this.state.addrress.address1.length!==0 && this.state.addrress.city!=='' && this.state.addrress.name.length!==0 && this.state.addrress.pincode.length===6){
        service.addAddress(this.state.addrress).then((response) => {
            if (response.status == 201) {
                this.setState({
                    addrress:{
                        name: '',
                        contactNo: '',
                        address1: '',
                        houseNo: '',
                        pincode: '',
                        city: '',
                        differentBilling: '',
                        addressType: ''
                    }
                })
                this.allAddress();
            }
        }, (error) => {
            console.log(error);
        });
    }
    else{
        this.setState({
            showError:true,
            message : 'Invalid field value!'
        })
    }
    }

    componentDidMount() {
        this.allAddress();
    }

    checkPincode = (pincode,operation,id) => {

        service.verifyPinCode(this.state.addrress.pincode===''?pincode:this.state.addrress.pincode).then((response) => {
                this.setState({
                    ...this.state,
                    activeForDelivery: response.data,
                    
                });

                if(operation === "select address" && id.length!==0){
                    this.selectAddress(id,response.data)
                }
           
        }, (error) => {
            console.log(error);
        });
    }

    getErrorClassName = (errorField) => {
        if (errorField) {
            return "form-control";
        } else {
            return "form-control is-invalid";
        }
    }

    handleChange = (e, field) => {
        let state = JSON.parse(JSON.stringify(this.state));
        state.addrress[field] = e.target.value;
        this.setState(state);
        this.setState({
            showError:false
        })
    }

    render() {
        return (
            <React.Fragment>
                                {
                                    this.state.showError && (
                                        <div className="col-12">
                                            <div className={"alert alert-dismissible fade show alert-danger"} role="alert">
                                                {this.state.message}
                                            </div>
                                        </div>
                                    )
                                }
                <div className="col-12 position-relative" style={{ border: "1px solid #DDD", borderRadius: "4px", display: this.state.addNewAddress ? 'block' : 'none' }}>
                    <h6 className="pt-3 pb-3 pl-3 pr-3 font-weight-bold" style={{ position: 'absolute', background: '#f8f9fa', left: '0px', width: '100%' }}>Customer Details</h6>
                    <div className="row mt-5 pt-3 pb-3">
                        <div className="col-12">

                            <form>
                                <div className="form-group">
                                    <input value={this.state.addrress.name} onChange={(e) => this.handleChange(e, "name")} type="text" className="form-control" placeholder="Full Name" />
                                </div>
                                <div className="form-group">
                                    <input value={this.state.addrress.contactNo} onChange={(e) => this.handleChange(e, "contactNo")} type="number" className="form-control" placeholder="Mobile NO." />
                                </div>
                                <div className="form-group">
                                    <input value={this.state.addrress.houseNo} onChange={(e) => this.handleChange(e, "houseNo")} type="text" className="form-control" placeholder="House No./ Flat No." />
                                </div>
                                <div className="form-group">
                                    <textarea value={this.state.addrress.address1} onChange={(e) => this.handleChange(e, "address1")} name="" id="" cols="30" rows="4" className="form-control"
                                        placeholder="Delivery Address"></textarea>
                                </div>

                                <div className="form-group">
                                    <input onBlur={this.checkPincode} value={this.state.addrress.pincode} onChange={(e) => this.handleChange(e, "pincode")}
                                        type="number" className={this.getErrorClassName(this.state.activeForDelivery)} placeholder="Pincode" />
                                    <div style={{ display: this.state.activeForDelivery ? 'none' : 'block' }} className="invalid-feedback">
                                        Sorry, We are currently unavialble at following pincode.
                                 </div>
                                </div>
                                <div className="form-group">
                                    <input value={this.state.addrress.city} onChange={(e) => this.handleChange(e, "city")} type="text" className="form-control" placeholder="City" />
                                </div>

                                <div className="form-group">
                                    <label className="form-check-label" htmlFor="exampleCheck1">Address Type</label>
                                    <div className="row">
                                        <div className="col-4">
                                            <input checked={this.state.addrress.addressType == "HOME"} onChange={(e) => this.handleChange(e, "addressType")} id="address" type="radio" name="address" value="HOME" />
                                            <span className="ml-2">HOME</span>
                                        </div>
                                        <div className="col-3">
                                            <input checked={this.state.addrress.addressType == "PG"} onChange={(e) => this.handleChange(e, "addressType")} id="address" type="radio" name="address" value="PG" />
                                            <span className="ml-2">PG</span>
                                        </div>
                                        <div className="col-5">
                                            <input checked={this.state.addrress.addressType == "OTHERS"} onChange={(e) => this.handleChange(e, "addressType")} id="address" type="radio" name="address" value="OTHERS" />
                                            <span className="ml-2">OTHERS</span>
                                        </div>
                                    </div>
                                </div>


                                <button onClick={this.addAddress} type="button" className="btn btn-primary btn-block btn-sm">Submit</button>
                                {
                                    !this.props.hideSelectFromSaved && (
                                        <input onClick={this.addNewAddressFlag} type="button" className="btn btn-success btn-block btn-sm" value="Select From Saved Address" />
                                    )
                                }

                            </form>
                        </div>
                    </div>
                </div>

                <div className="col-12 position-relative" style={{ border: "1px solid #DDD", borderRadius: "4px", display: this.state.addNewAddress ? 'none' : this.state.selectedAddressId == 0 ? 'block' : 'none' }}>
                    <h6 className="pt-3 pb-3 pl-3 pr-3 font-weight-bold" style={{ position: 'absolute', background: '#f8f9fa', left: '0px', width: '100%' }}>Select Address</h6>
                    <div className="row mt-5 pt-3 pb-3 sidebar">
                        {
                            this.state.allAddress.map((address, aKey) => (
                                <React.Fragment key={aKey} >
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <span>Name</span>
                                            </div>
                                            <div className="col-6">
                                                <span className="text-black-50">{address.name}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <span>Contact</span>
                                            </div>
                                            <div className="col-6">
                                                <span className="text-black-50">{address.contactNo}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <span>Address</span>
                                            </div>
                                            <div className="col-6">
                                                <span className="text-black-50">{address.houseNo} {address.address1} {address.address2}</span>&nbsp;
                                                <span className="text-black-50">{address.pincode} {address.city}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <input type="button" onClick={(e) => this.checkSelectedAddress(address.Id,address.pincode)} className="btn btn-primary btn-block btn-sm" value="Select Address" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    
                                    
                                </React.Fragment>
                                

                            ))
                            
                        }
                        <div className="col-12 mt-2" >
                            <input onClick={this.addNewAddressFlag} type="button" className="btn btn-success btn-block btn-sm" value="Add New Address" />
                        </div>
                    </div>
                </div>


                <div className="col-12 position-relative" style={{ border: "1px solid #DDD", borderRadius: "4px", display: this.state.selectedAddressId == 0 ? 'none' : 'block' }}>
                    <h6 className="pt-3 pb-3 pl-3 pr-3 font-weight-bold" style={{ position: 'absolute', background: '#f8f9fa', left: '0px', width: '100%' }}>Place Order</h6>
                    <div className="row mt-5 pt-3 pb-3">

                        <div className="col-12">
                            <div className="row">
                                <div className="col-4">
                                    <span>Name</span>
                                </div>
                                <div className="col-6">
                                    <span className="text-black-50">{this.state.selectedAddress.name}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <span>Contact</span>
                                </div>
                                <div className="col-6">
                                    <span className="text-black-50">{this.state.selectedAddress.contactNo}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <span>Address</span>
                                </div>
                                <div className="col-6">
                                    <span className="text-black-50">{this.state.selectedAddress.houseNo} {this.state.selectedAddress.address1} {this.state.selectedAddress.address2}</span>
                                    <span className="text-black-50">{this.state.selectedAddress.pincode} {this.state.selectedAddress.city}</span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12 mt-2" >
                                    <input onClick={this.addNewAddressFlag} type="button" className="btn btn-success btn-block btn-sm" value="Add New Address" />
                                </div>

                                <div className="col-12 mt-2">
                                    {
                                        this.props.isMedical != true && !this.props.hideSelectFromSaved && (
                                            <button onClick={(e) => this.props.placeOrder(this.state.selectedAddressId)} type="button" className="btn btn-primary btn-block" disabled={this.props.updating}>
                                            {
                                                this.props.updating?<div><span className="ml-2">Processing &nbsp;</span><span className="spinner-border spinner-border-sm"  role="status"></span></div>:"Place Order"
                                            }
                                            </button>)
                                    }
                                    {
                                        this.props.isMedical==true && (
                                            <div>
                                            <div className="row mb-2">
                                                
                                                <div className="col-12">
                                                    <button  onClick={(e) =>this.props.placeOrder(this.state.selectedAddressId)} type="button" className="btn btn-primary btn-block " disabled={this.props.updating}>
                                                    {
                                                        this.props.updating?<div><span className="ml-2">Processing &nbsp;</span><span className="spinner-border spinner-border-sm"  role="status"></span></div>:"Submit Enquiry"
                                                    }
                                                    </button>
                                                </div>
                                            </div>
                                            <span >Note:<span className="mt-2 text-black-50" > Please submit your enquiry for further process.</span></span>
                                            </div>
                                            )

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </React.Fragment>
        );
    }
}

export default CustomerDetails;