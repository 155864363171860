import React, { Component } from 'react';
import { connect } from 'react-redux';


import ReportIssue from './../../Generic/Modal/reportIssue';

class InvoiceItem extends Component {

    handleIssue =(type,invoiceNumber) =>{
        this.props.showInvoiceIssueModal({
            type : type,
            issueItemId : invoiceNumber
        });

    }


    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-6 col-md-2">
                        <span className="d-block">Invoice Type</span>
                        <span className="text-black-50">{this.props.invoice.invoiceType}</span>
                    </div>
                    <div className="col-6 col-md-2">
                        <span className="d-block">Invoice Number</span>
                        <span className="text-black-50">{this.props.invoice.invoiceNumber}</span>
                    </div>
                    <div className="col-6 col-md-2">
                        <span className="d-block">Invoice Amount</span>
                        <span className="text-black-50">Rs {this.props.invoice.amount + this.props.invoice.interestGenerated}</span>
                    </div>

                    <div className="col-6 col-md-2">
                        <span className="d-block">Generated On</span>
                        <span className="text-black-50">{new Date(this.props.invoice.generatedOn).toDateString()}</span>
                    </div>

                    <div className="col-12 col-md-2">
                        <span className="d-block">Status</span>
                        <span className={this.props.invoice.isPaid == true ? 'text-success' : 'text-danger'}>{this.props.invoice.isPaid == true ? 'Paid' : 'Pending'}</span>
                        {
                            ! this.props.invoice.isPaid && (
                                <button onClick={ () => this.props.makePayment(this.props.invoice.invoiceNumber) } className="btn btn-sm btn-outline-success ml-2 d-inline cursor-pointer " disabled={this.props.updating}>
                                    {
                                        this.props.updating?<div><span className="ml-2">Wait &nbsp;</span><span className="spinner-border spinner-border-sm"  role="status"></span></div>:"Pay Now"
                                    }
                                </button>
                            )
                        }

                    </div>


                    <div className="col-12 col-md-2">
                        <div className="row">
                            <div className="col-md-12 col-6">
                                <a target="_blank" href={'./account/invoices/'+this.props.invoice.invoiceNumber}>
                                    <span className="text-primary d-block" style={{ cursor: 'pointer' }}>View Invoice</span>
                                </a>
                            </div>
                            <div className="col-md-12 col-6">
                                <span className="text-primary" style={{ cursor: 'pointer'}} onClick={() => this.handleIssue('invoice',this.props.invoice.invoiceNumber)}>Report Issue</span>
                                
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        reportIssue: currentState.reportIssue
    };
};


const mapActionsToProps = (dispatch) => {
    return {
        showInvoiceIssueModal : (data) => {
            dispatch({ type : 'issue.modal.invoice.open', data : data })
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(InvoiceItem);