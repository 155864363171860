import React, { Component } from 'react';
import { connect } from 'react-redux';


import * as endpoints from '../../../Service/endpoints';

class SingleProduct extends Component {

    style = {
        borderStyle: {
            border: "1px solid hsla(240,2%,88%,.74)",
            borderRadius : '8px',
        },
        productBorder: {
            display: 'inline-block',
            position: 'relative',
            top: '-5px'
        }
    }


    getProductUrl = (product) => {

        if (typeof product.category != "undefined") {
            return endpoints.getRoute(`${product.category.slug}/${product.subCategory.slug}/${product.slug}`);
        } else {
            return "#";
        }

    }

    openContactForm =(product)=>{
        console.log(product);

        this.props.showProductModal({
            productName : product.productName,
            priceRange : product.priceRange,
            imageUrl : product.imageUrl
        });
    }

    render() {

        return (
            <div className={'col-12 col-md-4 mb-3 col-'+ this.props.styleValue.padding} >
                <div style={this.style.borderStyle}>
                    <div className="row m-0 ph-item">
                        <div className="col-12 p-0">
                            <img style={{ width: 'inherit', maxHeight: '300px' }} src={this.props.product.imageUrl} alt={this.props.product.imageUrl} />
                        </div>
                    </div>

                    <div className="row m-1">
                        <div className="col-12 pb-3">
                            <a href={this.getProductUrl(this.props.product)} type="button" >
                                <span className="font-weight-bold">{this.props.product.productName}</span>
                            </a>

                            <div className="mt-1">
                                
                                 <div>
                                    <span className="text-black-50">Price range :</span>
                                    <span>₹ {this.props.product.priceRange}</span>
                                </div>
                               
                                <span className="d-block">
                                    <span className="text-black-50">Description : </span>
                                    <span className="ml-2">{this.props.product.specification}</span>
                                </span>

                                <span className="d-block">
                                    <span className="text-black-50">Response Time : </span>
                                    <span className="ml-2">{this.props.product.responseToUser}</span>
                                </span>
                            </div>

                            <div className="text-right">
                                <button onClick={()=>this.openContactForm(this.props.product)} type="button" className="btn btn-sm btn-outline-primary mt-2"> Contact Us </button>
                            </div>

                        </div>          
                    </div>

                </div>     
            </div>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        productSurvey : currentState.productSurvey
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        showProductModal: (data)=>{
            dispatch({ type: 'product.survey.show', data : data })
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(SingleProduct);