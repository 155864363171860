import React, { Component } from 'react';
import H2Title from './../../../Generic/Element/Heading';
import * as keyclockService from './../../../../Service/keyclockService';
import * as service from './../../../../Service/service';
import { connect } from 'react-redux';
// import GoogleSignIn from '../GoogleLogin/GoogleSignIn';

class LoginComponent extends Component {

    state = {
        isLoginError: false,
        isDisabled:false
    }

    handleChange = (e, element) => {
        let tmpState = JSON.parse(JSON.stringify(this.props.state));
        tmpState.userData[element] = e.target.value;
        this.props.updateState(tmpState);
    }

    loginUser = () => {

        this.setState({
            ...this.state,
            isLoginError: false,
            isDisabled:true
        });

        keyclockService.getUserToken({
            username: this.props.state.userData.username,
            password: this.props.state.userData.password
        }).then((response) => {
            localStorage.setItem("keycloak", JSON.stringify(response.data));
            this.props.loginUser(response.data);

            var event = new Event('login');
            window.dispatchEvent(event);

            this.getLoginInfo();


            if(window.location.search.indexOf("url") != -1){
                window.location.href = window.location.search.split("url=")[1];
            }
            
        }, (err) => {
            this.setState({
                ...this.state,
                isLoginError: true,       
                isDisabled:false        
            });
        });

    }

    getLoginInfo = () => {
        service.getUserInfo().then((response) => {

            this.props.updateUserInfo(response.data);

            setTimeout(() => {
                this.props.hideLoginWindow();
            }, 200)
        }, (error) => {
            console.log(error);
        });
    }

    setDisabled=()=>{
        this.setState({isDisabled:true});
    }


    render() {
        return (
            <React.Fragment>
                <H2Title value="Login to continue" />

                {/* <GoogleSignIn /> */}
                
                {
                    this.state.isLoginError && (
                        <div className="mt-3">
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                <strong>Invalid user credentials !</strong> Please check & try again.
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    )
                }

                <div className="form-group mt-3">
                    <input value={this.props.state.userData.username} onChange={(e) => this.handleChange(e, 'username')} type="email" className="form-control" id="exampleInputEmail1"
                        aria-describedby="emailHelp" placeholder="Enter email" />
                    <small id="emailHelp" className="form-text text-muted">We'll never share your email
                                            with anyone else.</small>
                </div>
                
                <div className="form-group">
                    <input value={this.props.state.userData.password} onChange={(e) => this.handleChange(e, 'password')} type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                    {
                        !this.props.isGeneralLogin && (
                            <a onClick={(e) => this.props.changeScreen('forgot')} id="emailHelp" className="form-text text-muted text-right" style={{ cursor: 'pointer' }}>Forgot Password ?</a>
                        )
                    }
                </div>
                        
                
                <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    <label className="form-check-label text-black-50" htmlFor="exampleCheck1">Remember Me</label>
                </div>
                <button onClick={this.loginUser} type="button" className={this.props.isGeneralLogin ? 'btn btn-primary btn-block' : 'btn btn-primary btn-sm btn-block'} disabled={this.state.isDisabled}> Sign In</button>
                {
                 !this.props.isGeneralLogin && (
                 <button type="button" onClick={(e) => this.props.changeScreen('register')} className="btn btn-light btn-sm btn-block">Register</button>
                 )
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        userState: currentState.userState,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        loginUser: (data) => {
            dispatch({ type: 'user.auth.login', data: data });
        },
        hideLoginWindow: (data) => {
            dispatch({ type: 'login.window.hide', data: data });
        },
        updateUserInfo: (data) => {
            dispatch({ type: 'login.update.userInfo', data: data });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(LoginComponent);