import React, { Component } from 'react';
import { Helmet } from 'react-helmet';


class ProductFAQComponent extends Component {

    state = {
        faq: [
            {   id : 1,
                question: 'What is this security deposit ?',
                description: 'Voorent requires you to pay a refundable security deposit of about 1 months rent at the time of booking your order. This amount will be refunded to you when you terminate your subscription.'
            }, {
                id : 2,
                question: 'When will you return my security deposit ?',
                description: 'Security deposit will be refunded after you terminate the subscription and we pick up all the furniture from your home.'
            }, {
                id : 3,
                question: 'Is there a minimum rental tenure? What happens if I stop my subscription before completing this minimum tenure?',
                description: 'In case there is an early termination of the order as placed by the User, the User can request for early closure by informing the Company 2 weeks prior to the selected closure date. In such a case where the User wishes to terminate the order earlier than the prescribed time, the Company shall not refund any security deposit to the User at any point owing to early termination of the agreement. ( security deposit will be forfeited).'
            }
        ]
    }

    render() {
        return (
            <div className="col-12 p-0 mt-3" style={{ border: '1px solid #DDD' }}>
                <Helmet>
                    <meta name="description" content="We are offering a wide range of furniture and appliance on rent in delhi with free delivery within2-3 days in order to make our services unique." />
                    <meta name="keywords" content="Furniture on rent in Delhi" />
                    <meta name="title" content="Rent premium quality furnituure in delhi at affordable rates" />
                </Helmet>
                <h6 className="mt-3 font-weight-bold text-center">Frequenty Asked Questions</h6>
                <div className="row pl-2 pr-2 pt-3 pb-3">
                    <div className="col-12">
                        <div id="accordion">
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                {
                                this.state.faq.map((elem, fIndex) => (
                                 <div key={fIndex}>
                                    <li >{elem.question}</li>
                                    <p>{elem.description}</p>
                                </div>
                                ))
                            }
                            </ul>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductFAQComponent;