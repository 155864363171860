import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from './../../Generic/Header/HeaderComponent';
import Footer from './../../Generic/Footer/FooterComponent';
import BreadCrumbs from './../../Generic/BreadCrumbs/BreadCrumbs';

import SuccessComponent from './SuccessComponent';
import FailureComponent from './FailureComponent';

class OrderConfirmationComponent extends Component {

    state = {
        type: ''
    }

    componentDidMount() {
        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: './' }, { name: 'Order Status', link: '#' }],
            showSidebar: false,
            sidebar: []
        });
    }

    constructor(props) {
        super(props);
        this.state = ({
            ...this.state,
            type: props.match.params.status
        });
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <BreadCrumbs />
                <main role="main" className="container mt-5">
                    <div className="row">
                        {
                            this.state.type == "success" ?
                                (<SuccessComponent order={this.props.order.current} />) : (<FailureComponent />)
                        }
                    </div>
                </main>
                <Footer />
            </React.Fragment>
        );
    }
}


const mapStateToProps = (currentState) => {
    return {
        order: currentState.order,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }
    };
}


export default connect(mapStateToProps, mapActionsToProps)(OrderConfirmationComponent);