import React, { Component } from 'react';

import HeTitle from './../../Generic/Element/Heading';
import MdAdd from 'react-ionicons/lib/MdAdd';


import * as service from './../../../Service/service';

import AddressElement from './AddressElement';


class AddressComponent extends Component {

    state = {
        address: [],
        isLoaded : false
    }

    getAllAddress = () => {

        service.allAddress().then((response) => {
            this.setState({
                ...this.state,
                address: response.data,
                isLoaded : true
            })

        }, (error) => {
            console.log(error);
        });
    }

    deleteAddress = (id) => {
        service.deleteAddress(id).then((response) => {
            if (response.status == 202) {
                this.getAllAddress();
            }
        }, (error) => {
            console.log(error);
        });
    }

    componentDidMount() {
        this.getAllAddress();
    }

    render() {
        return (
            <div className="row m-3">
                <div className="col-12">
                    <HeTitle value="Manage Address" />
                    <a class="btn btn-success float-right" href='/user/add/address'>Add Address</a>

                    <div className="row element-border mt-3">

                        {
                            !this.state.isLoaded && (<div className="col-12 m-auto" style={{minHeight:'250px'}}>
                                <div className="row">
                                    <div className="col-12 text-center mt-5">
                                        <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <span className="pl-3 text-black-50 d-block" >Checking backend . . </span>
                                    </div>
                                </div>
                            </div>)
                        }

                        {
                            this.state.isLoaded && this.state.address.length == 0 && (
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 text-center mt-5">
                                            <img height="150px" src="./svg/address.svg" />
                                            <h5 className="mt-3 mb-5">No addresses found, Your address will be shown here once added.</h5>
                                        </div>
                                    </div>
                                </div>
                            )
                        }


                        {
                            this.state.address.length != 0 && this.state.address.map((address, aIndex) => (
                                <React.Fragment key={aIndex}>
                                    <AddressElement deleteAddress={this.deleteAddress} address={address} />
                                    {aIndex == this.state.address.length - 1 ? '' : (<hr />)}
                                </React.Fragment>
                            ))
                        }

                    </div>
                </div>
            </div>
        );
    }
}

export default AddressComponent;