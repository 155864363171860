import React, { Component } from 'react';

import H2Title from './../../../Generic/Element/Heading';
import HeaderComponent from './../../../Generic/Header/HeaderComponent';
import FooterComponent from './../../../Generic/Footer/FooterComponent';
import BreadCrumbs from './../../../Generic/BreadCrumbs/BreadCrumbs';

import Blog7 from './Blog7';

import { connect } from 'react-redux';

class Blog7Component extends Component {

    componentDidMount(){
        window.scrollTo(0,0)
        this.props.updateBreadcumbs({
            navigation : [{ name: 'Home', link: './' }, { name: "Seasonal Self-Care", link: '#' }],
            showSidebar : false,
            sidebar : []
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderComponent />
                <BreadCrumbs />
                <main role="main" className="container mt-5">
                    <div className="row m-auto">
                        <H2Title value={'Seasonal Self-Care'} />
                        <div className="col-12 mt-3">
                            <Blog7 />
                        </div>

                    </div>
                </main>

                <FooterComponent />
            </React.Fragment>
        );
    }
}

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }
    };
}

export default connect(null, mapActionsToProps)(Blog7Component);