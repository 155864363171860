import React, { Component } from 'react';
import RentSelector from './RentSelector';
import OrderTerms from './OrderTerms';
import {Helmet} from 'react-helmet';

class ProductInfoComponent extends Component {

    state = {
        metaTags: [
            {   id : 1,
                keyword: 'Tv on rent in gurgaon',
                title: 'Why purchase when you can rent a T.V in gurgaon at affordable rates',
                description: 'We are offering you a premium quality of Televison on rent in gurgaon on discounted price. Rent now and get 5% Dicount .'
            }, 
            {
                id : 2,
                keyword: 'Rent appliances in delhi',
                title: 'Rent home appliances in delhi at affordable rates.',
                description: 'Living in a rented flat and want home appliances on rent? We are here with the solution, Rent home appliances in delhi on affordable rates from voorent.'
            }, 
            {
                id : 3,
                keyword: 'Bed on rent in gurgaon',
                title: 'Beds on rent on affordable rates | premium quality beds',
                description: 'We are offering beds on rent in gurgaon at affordable rates to make your bedroom look more aesthetic.'
            },
            {   id : 4,
                keyword: '5 Seater Sofa on rent',
                title: 'Sapphire blure 5-seater sofa on rent | Affordable Price',
                description: 'Sapphire blue 5-seater sofa on rent. The radiant colour and appealing formation gives this 5 seater sofa set a precise appeal for every type of room.'
            }, 
            {
                id : 5,
                keyword: 'Sofa Set on rent',
                title: 'Sofa set on rent in delhi and gurgaon',
                description: 'Voorent brings you a wide range of premium quality sofa set on rent in Delhi , Gurgaon at a very affordable rates.'
            }, 
            {
                id : 6,
                keyword: 'Furniture on rent in Delhi',
                title: 'Rent premium quality furnituure in delhi at affordable rates',
                description: 'We are offering a wide range of furniture and appliance on rent in delhi with free delivery within2-3 days in order to make our services unique.'
            },
            {   id : 7,
                keyword: 'Appliances on rent in gurgaon',
                title: 'Rent Necessary home appliance in gurgaon | Delhi',
                description: 'We bring you a wide range of home appliances in rent in gurgaon with quick delivery with 2-3 days of ordering.'
            }, 
            {
                id : 8,
                keyword: 'Table on rent in delhi',
                title: 'Premium study table on rent on discounted price',
                description: 'Struggling to get a study table? Explore the wide range of tables on rent in delhi at a very affordable price with free delivery within 2-3 days.'
            }, 
            {
                id : 9,
                keyword: 'Dining table on rent in gurgaon',
                title: 'Dinning Table available for rent | RENT NOW!!!!',
                description: 'Premium quality Dinning table on rent in gurgaon I voorent brings you a wide range of premium quality furniture on rent.'
            },
            {   id : 10,
                keyword: 'fridge on rent gurgaon',
                title: 'Refrigerator available for rent in delhi NCR',
                description: 'Complete your kitchen with our wide range fridge and double door refrigerator available for rent in gurgaon with delivery within 2-3 days.'
            }, 
            {
                id : 11,
                keyword: 'Air purifier on rent',
                title: 'Best quality air purifier on rent at a pocket friendly price',
                description: 'Get clean air in your room with our Air purifier available for rent in delhi and gurgaon at a very affordable price I Rent now.'
            }, 
            {
                id : 12,
                keyword: 'Mattress on rent',
                title: 'Premium quality matress on rent in Delhi & Gurgaon',
                description: 'This New year upgrade your comfort with our mattress available for rent in delhi and gurgaon at a very pocket friendly price.'
            },
            {   id : 13,
                keyword: 'single door fridge on rent',
                title: 'Get a refrigerator on rent at a pocket friendly price',
                description: 'Complete your kitchen with our single door fridge on rent in delhi and gurgaon I why purchase when you can rent at a pocket friendly price.'
            }, 
            {
                id : 14,
                keyword: 'appliance rent',
                title: 'Rent Furniture and appliance in Delhi NCR',
                description: 'Voorent brings you a wide range of furniture and appliance on rent I You are here to make the demands and we to meet them.'
            }, 
            {
                id : 15,
                keyword: 'Affordable furniture on rent',
                title: 'Rent Furniture and appliance in Delhi NCR',
                description: 'Voorent is offering a wide range of affordable furniture on rent in delhi and gurgaon with free delivery eithin 2-3 days I RENT NOW.'
            }
        ]
    }

    render() {
        return (
            <div>
                <Helmet>{
                        this.state.metaTags.map((elem, fIndex) => (
                            <div key={fIndex}>
                            <title>{elem.title}</title>
                            <meta name="description" content={elem.description} />
                            <meta name="keywords" content={elem.keyword} />
                        </div>
                        ))
                    }
                </Helmet>
                
                <h3 className="heading">{this.props.product.name}</h3>
                   
                <p className="mt-2" style={{ lineHeight: '20px', fontSize:'15px' }}>
                    {this.props.product.description} 
                </p>
                <RentSelector
                    showSpinner={this.props.showSpinner}
                    showOptions={this.props.showOptions}
                    addItemToCart={this.props.addItemToCart}
                    quantity={this.props.quantity}
                    manageQuantity={this.props.manageQuantity}
                    durationSelected={this.props.durationSelected}
                    showPrice={this.props.showPrice}
                    selectDuration={this.props.selectDuration}
                    inventory={this.props.inventory}
                    isMedical={this.props.product.isMedical}
                    status={this.props.product.status}
                    isVirtual={this.props.product.isVirtual}
                    tagName={this.props.product.tagName}
                     />
             {/* <OrderTerms /> */}
            </div>
        );
    }
}

export default ProductInfoComponent;