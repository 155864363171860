import React, { Component } from 'react';

class SubCategoryComponent extends Component {
    render() {
        return (
            <div>
                <h1>This is sub SubCategoryComponent</h1>
            </div>
        );
    }
}

export default SubCategoryComponent;