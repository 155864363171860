import React, { Component } from 'react';

import * as service from './../../../../Service/service';

class FilterComponent extends Component {

    state = {
        categorySelected : this.props.category,
        subCategory : this.props.subCategorie,
        categoryList: [],
        subCategorieList: []
    }

    styles = {
        patrentClass: {
            border: '1px solid #ddd',
            borderRadius: '4px',
            position: 'relative',
            height: 'min-content'
        },
        h6: {
            position: 'absolute',
            width: '100%',
            left: '0px',
            background: '#f8f9fa',
            padding: '10px',
            fontWeight: 'bold'
        }
    }

    render() {
        return (
            <div className="col-3 col-md-3 pb-3 d-none d-md-block d-lg-block d-xl-block" style={this.styles.patrentClass}>
                <h6 style={this.styles.h6}> Filter Results</h6>
                <div className="row mt-5">
                    {
                        this.props.categoryList.map((category, cIndex) => (
                            <div key={cIndex} className="col-12 p-2 pl-3">
                                <div className="form-check">
                                    <input onChange={this.props.changeCategory} className="form-check-input" type="checkbox" value={category.slug} id={category.slug} name="category" checked={this.props.checkSelectedCategory(category.slug)}/>
                                    <label style={{cursor : 'pointer'}} className="form-check-label ml-2 text-black-50" htmlFor={category.slug}> {category.name} </label>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <hr />
                <h6 className="font-weight-bold text-capitalize">{this.props.category}</h6>
                <div className="row">

                    {
                        this.props.subCategoryList.map((subCategory, sIndex) => (
                            <div key={sIndex} className="col-12 p-2 pl-3">
                                <div className="form-check">
                                <input onChange={this.props.changeSubCategory} className="form-check-input" type="checkbox" value={subCategory.slug} id={subCategory.slug} name="subcategory" checked={this.props.checkSelectedSubCategory(subCategory.slug)}/>
                                    <label style={{cursor : 'pointer'}} className="form-check-label ml-2 text-black-50" htmlFor={subCategory.slug}> {subCategory.name} </label>
                                </div>
                            </div>
                        ))
                    }

                    <div className="col-12 p-2 pl-3">
                        <div className="form-check">
                            <a href="#" > View More + </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FilterComponent;