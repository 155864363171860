import React, { Component } from 'react';
import CartItem from './CartItem';

class CartItemsWrapper extends Component {


    render() {
        return (
            <div className="row mt-1 ml-1 mr-1">
                <div className="col-12" style={{ border: '1px solid #DDD', position: 'relative', borderRadius: '4px' }} >
                    <h6 className="pt-3 pb-3 pl-3 pr-3 font-weight-bold" style={{ position: 'absolute', width: '100%', background: '#f8f9fa', left: '0px' }}
                    >Order Details
                    </h6>
                    <div className="row mt-5 p-3">
                        <div className="col-12">

                            {
                                this.props.cart.showCart == 0 && (
                                    <React.Fragment>
                                        <div className="row text-center m-auto">
                                            <div className="col-12">
                                                <div className="spinner-border"></div>
                                                <span className="font-weight-bold ml-3"> Loading Cart Items . . </span>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }

                            {
                                this.props.cart.showCart && this.props.cart.cart.cartProductList.map((product, pIndex) => (
                                    <React.Fragment key={pIndex}>
                                        <CartItem 
                                            manageQuantity = {this.props.manageQuantity}
                                            deleteProductFromCart={this.props.deleteProductFromCart} 
                                            product={product} />
                                        {pIndex == this.props.cart.cart.cartProductList.length - 1 ? '' : (<hr />)}
                                    </React.Fragment>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CartItemsWrapper;