import React, { Component } from 'react';

class FooterSEO extends Component {
    state = { 
        showMore:false
     }

    readMore =()=>{
        this.setState({showMore:true});
    }

    readLess=()=>{
        this.setState({showMore:false});
    }

    render() { 
        return ( 
            <React.Fragment>
                <div className="col-12" style={{color:"grey",fontSize:"15px",fontWeight:"400px"}}>
                <h5><strong>Voorent: One stop solution for furniture and appliance rental in Gurugram</strong></h5>

<p>Gurgaon is full of ample opportunities, but to focus entirely on your work, you need to be completely burden-free. The first step towards a completely free mind is to set up a home with the least maintenance and total comfort without investing too much. Why not try home <b>furniture on rent in Gurugram</b> by using our services. The product range we offer is vast and specially designed to cater to the needs of all kind of clients. Be it an individual or a family, an employee or anyone else, we surely have something for everyone. Our work is big and so the range of products is. Voorent Now!</p>

{!this.state.showMore && (<a onClick={this.readMore} style={{cursor:"pointer"}}>Read More &gt;</a>)}

{this.state.showMore && (
    <div>
<h5><strong>Rent The Best furniture, Appliances</strong></h5>

<p>Furniture rentals in Gurgaon are picking up and turning into a way of life. Life is just easier without having to deal with heavy furniture that is difficult to get rid of, especially if you&rsquo;re bored of it or moving homes. Plus, people just like having the option of changing their furniture at will or returning it when they want something else. Now, you may not know where to rent furniture in Gurgaon, but that&rsquo;s why we&rsquo;re here. Voorent Now!</p>

<h5><strong>Why to choose Voorent?</strong></h5>

<ol>

<li>Your home deserves the best furniture</li>

<li>We manufacture furniture at our own facility and have a delivery team of our own.</li>

<li>Our all products are either manufactured by ourself or purchased in brand new condition.</li>

<li>High standards are maintained during the manufacturing of each and every piece of furniture.</li>

<li>The home appliances on rent are of the best brands in the Business i.e. Samsung, Haier, Videocon, LG, Voltas, Blue Star. etc.</li>

<li>You don&rsquo;t have to worry about maintenance</li>

<li>Hassle free and free delivery</li>

<li>Easy to rent and affordable</li>

</ol>

<h5><strong>You can rent everything under one roof</strong></h5>

<p>Voorent is your best bet, be it <strong>furniture on rent</strong> or <strong>appliances on rent in Gurgaon</strong>. Choose from living room furniture, bedroom furniture, dining room, balcony, full home and appliances on rent. With Voorent, you can furnish any part of the house. Want to <strong>rent a sofa in Gurgaon</strong>? Or do you want to <strong>rent a double bed</strong>? We have it for you. You can even rent an entire living room package complete with a TV, sofa, cushions, coffee table, appliances etc. All in a few clicks.</p>

<h5><strong>Free Delivery installation Maintenance and Pick-up</strong></h5>

<p>Our process is simple, designed to take you through the process of browsing the products smoothly, choosing what all you need and finally placing the order. Once order is placed you have nothing else to do. Just sit back and relax. Our delivery team will co-ordinate with you and get everything delivered within 72 hours after contacting you as per your convenience and timing. We are here to help you to rent home furniture and appliances in the city.</p>

<h5><strong>What we offer in bedroom furniture category in Gurugram is:</strong></h5>

<ul>

<li>Single Bed on rent in Gurgaon,</li>

<li>Double Bed on rent in Gurgaon,</li>

<li>Wardrobe on rent in Gurugram,</li>

<li>Air Purifier on rent in Gurgaon,</li>

<li>Side table on rent in Gurugram and many more</li>

</ul>

<h5><strong>What we offer in living room furniture category in Gurgaon is:</strong></h5>

<ul>

<li>Sofa on rent in Gurugram (Single seater sofa, Two-seater sofa, Three-seater sofa, Five-seater sofa and L-Shaped sofa),</li>

<li>Centre Table on rent in Gurgaon</li>

</ul>

<h5><strong>What we offer in dining room furniture category in Gurugram is:</strong></h5>

<ul>

<li>4-Seater dining table on rent in Gurgaon,</li>

<li>6-Seater dining table on rent in Gurugram</li>

</ul>

<h5><strong>What we offer in appliances in Gurgaon is:</strong></h5>

<ul>

<li>Washing machine on rent in Gurgaon</li>

<li>Air Purifier on rent in Gurugram</li>

<li>LED tv 40&rdquo; on rent in Gurgaon</li>

<li>LED tv 32&rdquo; on rent in Gurgaon</li>

<li>Double door refrigerator on rent in Gurgaon</li>

<li>Single door refrigerator on rent in Gurugram</li>

<li>Solo microwave on rent in Gurgaon</li>

</ul>
{this.state.showMore && (<a onClick={this.readLess} style={{cursor:"pointer"}}>Read Less &gt;</a>)}
</div>
)}
                </div>
            </React.Fragment>
         );
    }
}
 
export default FooterSEO;