import React, { Component } from 'react';

import MdBasket from 'react-ionicons/lib/MdBasket';
import MdOptions from 'react-ionicons/lib/MdOptions';
import MdSubway from 'react-ionicons/lib/MdSubway';
import MdRibbon from 'react-ionicons/lib/MdRibbon';

class WhatWeDoComponent extends Component {

    state = {
        iconHeight: '36px',
        fontHeight: '16px'
    }

    adjustIcons = () => {
        if (document.body.clientWidth < 500) {
            this.setState({
                ...this.state,
                iconHeight: '24px',
                fontHeight: '13px'
            })
        }
    }

    getIcon = (iconName) => {
        let icon = null;
        switch (iconName) {
            case "basket": icon = (<MdBasket fontSize={this.state.iconHeight} color="#ffffff" />); break;
            case "options": icon = (<MdOptions fontSize={this.state.iconHeight} color="#ffffff" />); break;
            case "airplane": icon = (<MdSubway fontSize={this.state.iconHeight} color="#ffffff" />); break;
            case "ribbon": icon = (<MdRibbon fontSize={this.state.iconHeight} color="#ffffff" />); break;
            default: return null;
        }
        return icon;
    }

    componentDidMount() {
        this.adjustIcons();
    }

    render() {
        return (
            <div className="col-6 col-md-3 col-lg-3 equal">
                <div className="row p-2 m-1">
                    <div className="col-12">
                        {
                            this.getIcon(this.props.icon)
                        }
                    </div>
                    <div className="col-12" style={{ color: '#ffffff', fontWeight: 'bold', fontSize: this.state.fontHeight }}>
                        <span>{this.props.quality}</span>
                    </div>
                </div>
            </div>

        );
    }
}

export default WhatWeDoComponent;