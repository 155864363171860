import React, { Component } from 'react';
import * as endpoint from './../../../Service/endpoints';

class EmptyCart extends Component {
    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-12 text-center">
                       <img height="150px" src="./svg/empty-cart.svg" />
                    </div>
                    <div className="col-12 mt-5 text-center">
                            <h4 className="text-capitalize font-weight-bold">No items in cart</h4>
                            <span className="d-block text-black-50">Add a few items to your cart and <br/> come back here to make an easy renting.</span>
                            <a href={endpoint.getRoute('furniture')} className="btn btn-sm btn-outline-primary mt-2"> Checkout Now </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmptyCart;