import React, { Component } from 'react';
import H2Title from './../../Generic/Element/Heading';
import IosCard from 'react-ionicons/lib/IosCard';
import IosCashOutline from 'react-ionicons/lib/IosCashOutline';
import IosCardOutline from 'react-ionicons/lib/IosCardOutline';
import * as service from './../../../Service/service';
import ReportIssue from './../../Generic/Modal/reportIssue';
import DepositMonthly from './DepositMonthly';

class DepositComponent extends Component {

    styles = {
        parentBox: {
            border: '1px solid rgba(72, 94, 144, 0.16)',
            wordWrap: 'break-word',
            backgroundColor: '#fff',
            backgroundClip: 'border-box',
            borderRadius: '0.25rem'
        },
        title: {
            fontSize: '16px', color: "#004980"
        },
        info: {
            fontSize: '24px', color: "#e8554e"
        }
    }

    state = {
        deposit: {},
        info: {
            active: 0,
            refunded: 0,
            total: 0
        },
        isLoaded : false
    }

    listAllDeposits = () => {
        service.listDeposit().then((response) => {

            let info = {
                active: 0,
                refunded: 0,
                total: 0
            }

            let depositList = {};

            response.data.length != 0 && response.data.map((deposit) => {

                let month = new Date(deposit.txnDate).toDateString().split(" ")[1];
                let year = new Date(deposit.txnDate).toDateString().split(" ")[3];
                let final = `${month}-${year}`

                if (typeof depositList[final] == "undefined") {
                    depositList[final] = [];
                    depositList[final].push(deposit);
                } else {
                    depositList[final].push(deposit);
                }


                if (deposit.txnType == "DEPOSIT" && deposit.depositStatus == "ACTIVE") {
                    info.active += deposit.amount;
                    info.total += deposit.amount;
                } else if (deposit.txnType == "REFUND" && deposit.depositStatus == "ACTIVE") {
                    info.refunded += deposit.amount;
                    info.total += deposit.amount;
                    info.active += deposit.amount;
                }


            });

            this.setState({
                ...this.state,
                deposit: depositList,
                info: info,
                isLoaded : true
            });

        }, (error) => {
            console.log(error);
        });
    }

    componentDidMount() {
        this.listAllDeposits();
    }

    render() {
        return (
            <React.Fragment>
                <div className="row mt-3 mb-3 ml-1 mr-1">
                    <div className="col-12">
                        <H2Title value="Deposits" />


                        <div className="row">
                            <div className="col-12 col-md-4 mt-2">
                                <div className="row ml-1 mr-1 p-2" style={this.styles.parentBox}>
                                    <div className="col-4">
                                        <IosCard fontSize="64px" color="#004980" />
                                    </div>
                                    <div className="col-8 p-2 text-right">
                                        <span className="d-block text-uppercase" style={this.styles.title}>Active Deposit</span>
                                        <span className="d-block font-weight-bold" style={this.styles.info}>Rs {this.state.info.active}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 mt-2">
                                <div className="row ml-1 mr-1 p-2" style={this.styles.parentBox}>
                                    <div className="col-4">
                                        <IosCashOutline fontSize="64px" color="#004980" />
                                    </div>
                                    <div className="col-8 p-2 text-right">
                                        <span className="d-block text-uppercase" style={this.styles.title}>Deposit Refunded</span>
                                        <span className="d-block font-weight-bold" style={this.styles.info}>Rs {this.state.info.refunded}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 mt-2">
                                <div className="row ml-1 mr-1 p-2" style={this.styles.parentBox}>
                                    <div className="col-4">
                                        <IosCardOutline fontSize="64px" color="#004980" />
                                    </div>
                                    <div className="col-8 p-2 text-right">
                                        <span className="d-block text-uppercase" style={this.styles.title}>Total Deposit</span>
                                        <span className="d-block font-weight-bold" style={this.styles.info}>Rs {this.state.info.total}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Txn Report */}

                        <div className="row element-border mt-3 mr-1 ml-1">
                            <div className="col-12 p-3">

                                {
                                    !this.state.isLoaded && (<div className="col-12 m-auto" style={{minHeight:'250px'}}>
                                        <div className="row">
                                            <div className="col-12 text-center mt-5">
                                                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <span className="pl-3 text-black-50 d-block" >Checking backend . . </span>
                                            </div>
                                        </div>
                                    </div>)
                                }

                                {
                                    this.state.isLoaded && Object.keys(this.state.deposit).length == 0 && (
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 text-center mt-5">
                                                    <img height="150px" src="./svg/deposit.svg" />
                                                    <h5 className="mt-3 mb-5">No deposits till now, Your deposit details will be shown here once you rent somthing with us.</h5>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    Object.keys(this.state.deposit).map((depositKey, dIndex) => (
                                        <DepositMonthly key={dIndex} deposit={this.state.deposit[depositKey]} month={depositKey} />
                                    ))
                                }

                            </div>
                        </div>


                    </div>
                    <ReportIssue />
                </div>
            </React.Fragment>
        );
    }
}

export default DepositComponent;