import React, { Component } from 'react';
import * as service from './../../../Service/service';
import SingleProduct from './../../Generic/Element/SingleProduct';
import H2Title from './../../Generic/Element/Heading';
import FooterComponent from './../../Generic/Footer/FooterComponent';
import HeaderComponent from './../../Generic/Header/HeaderComponent';
import { connect } from 'react-redux';
import BreadCrumbs from './../../Generic/BreadCrumbs/BreadCrumbs';

class SearchComponent extends Component {
    state= {
        product : [],
        isLoaded: false
    }
    
    constructor(props) {
        super(props);
        this.state = ({
            ...this.state,
            query : props.match.params.query
        });
    }

    getSearchInfo = () => {
        service.searchItems(this.state.query).then((response) => {
            this.setState({
                ...this.state,
                product: response.data,  
                isLoaded: true             
            }) 
            
        },(error) => {
                console.log(error);
        });
    }


    componentDidMount(){
        this.getSearchInfo();
        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: './' }, { name: 'Search', link: '#' }],
            showSidebar: false,
            sidebar: []
        });
    }

   

    render() {
        return (
            <React.Fragment>

                <HeaderComponent />
                <BreadCrumbs />
                
                <main role="main" className="container m-auto">
                <div className="row mt-4">
                        <div className="col-12">
                            
                            <H2Title value={'Searched Product  '} />
                            {
                                this.state.isLoaded && (
                                <span className="mb-4 ml-5"><h6 className="ml-2 mb-3"><b>{this.state.product.length}</b> Products Found</h6></span>
                                )
                            }
                           
                            <div className="row">

                            {
                                            !this.state.isLoaded && <div className="col-12 m-auto">
                                                <div className="row">
                                                    <div className="col-12 text-center mt-5">
                                                        <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                        <span className="pl-3 text-black-50 d-block" >Checking Inventory ..</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                               
                                {
                                    this.state.isLoaded && this.state.product.map((prod, index) => (
                                        
                                        <span>{prod.name}</span> && <SingleProduct key={index} minTenure={"6 Months"} product={prod} styleValue={{ padding: '' }} />
                                            
                                        ))
                                }
                                 {
                                    this.state.isLoaded && this.state.product.length === 0 && (
                                        <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12 text-center mt-5">
                                                            <img alt="productList" height="80px" src="./../svg/noresult.svg" />
                                                            <h5 className="mt-3 mb-5 text-black-50">Sorry ! No Items available with following search option.</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    </main>
                    
                    <FooterComponent/>
            </React.Fragment>
        );
    }
}
 
const mapStateToProps = (currentState) => {
    return {
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps) (SearchComponent);