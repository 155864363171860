import React, { Component } from 'react';

class SettingsComponent extends Component {
    render() {
        return (
            <div>
                <h1>This is SettingsComponent</h1>
            </div>
        );
    }
}

export default SettingsComponent;