import React, { Component } from 'react';

import H2Title from './../../../Generic/Element/Heading';
import HeaderComponent from './../../../Generic/Header/HeaderComponent';
import FooterComponent from './../../../Generic/Footer/FooterComponent';
import BreadCrumbs from './../../../Generic/BreadCrumbs/BreadCrumbs';

import FAQList from './FAQList/FAQList';
import { connect } from 'react-redux';
import {Helmet} from "react-helmet";

class FAQComponent extends Component {

    componentDidMount(){
        window.scrollTo(0,0)
        this.props.updateBreadcumbs({
            navigation : [{ name: 'Home', link: './' }, { name: "FAQ", link: '#' }],
            showSidebar : false,
            sidebar : []
        });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <link rel="canonical" href="https://voorent.com/faqs" />
                </Helmet>
                <HeaderComponent />
                <BreadCrumbs />
                <main role="main" className="container mt-5">
                    <div className="row m-auto">
                        <H2Title value={'Frequently Asked Questions'} />

                        <div className="col-12 mt-3">
                            <FAQList />
                        </div>

                    </div>
                </main>

                <FooterComponent />
            </React.Fragment>
        );
    }
}

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        }
    };
}

export default connect(null, mapActionsToProps)(FAQComponent);