import React, { Component } from 'react';

import InvoiceItem from './InvoiceItem';

class InvoiceMonth extends Component {
    render() {
        return (
            <div className="row mt-4">
                <div className="col-12 mb-3 font-weight-bold">
                    <span>{this.props.month}</span>
                </div>

                {
                    this.props.invoices.map((invoice, iIndex) => (
                       <React.Fragment key={iIndex}>

                           
                           <InvoiceItem 
                                invoice={invoice} makePayment = {this.props.makePayment} 
                                updating={ this.props.selectedInvoice === invoice.invoiceNumber ? this.props.updating :  false} />
                        
                           { iIndex == this.props.invoices.length -1 ? '' : (<hr className="mt-3 mb-3" />) }
                       </React.Fragment>
                    ))
                }

            </div>
        );
    }
}

export default InvoiceMonth;