import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import * as service from './../../../Service/service';
import { connect } from 'react-redux';

// import keys from './../../../Config/config.json';

class OrderExtendModal extends Component {

    state = {
        months: ['Select Period', 3, 6, 9, 12],
        orderExtendList: [],
        extendedMonth: '',
        isUpdated: false,
        updating : false,
        razorpayInfo: {},
        razorPayKey : '',
        isLoaded: true
    }

    onMonthSelect = (event) => {
        this.setState({
            ...this.state,
            extendedMonth: event.target.value
        });
    }
    getRazorPayKeys=()=>{

        service.razorPayKeys().then((response)=>{
            this.setState({
                razorPayKey: response.data
            });
            
        }, (error) => {
            console.log(error);
        });
    }
    

    generateRazorPayExtendId = (orderNumber) => {

        service.getRazorpayExtendId(orderNumber).then((response) => {
            this.setState({
                ...this.state,
                razorpayInfo: response.data,
                selectedOrder: orderNumber
            });

            setTimeout(() => {
                this.initiatePayment();
            }, 100);
        }, (error) => {
            console.log(error);
        });
    }

    initiatePayment = () => {
        var options = {
            "key": this.state.razorPayKey,
            "amount": this.state.razorpayInfo.amount_due,
            "currency": this.state.razorpayInfo.currency,
            "name": "Voorent Pvt. Ltd",
            "description": "",
            "image": "https://voorent.com/voorent-storage/application/voorent.png",
            "order_id": this.state.razorpayInfo.id,
            "handler": (response) => {
                this.updateRazorpayPayments(response);
                this.setState({
                    ...this.state,
                    updating : true
                });
            },
            "modal": {
                "ondismiss": () => {
                   
                }
            },
            "prefill": {
                "name": `${this.props.userInfo.firstName} ${this.props.userInfo.lastName}`,
                "email": `${this.props.userInfo.email}`,
                "contact": `${this.props.userInfo.contact}`
            },
            "theme": {
                "color": "#01497f"
            }
        };

        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    updateRazorpayPayments = (paymentUpdate) => {

        

        let obj = {
            razorpayPaymentId: paymentUpdate.razorpay_payment_id,
            razorpayOrderId: paymentUpdate.razorpay_order_id,
            razorpaySignature: paymentUpdate.razorpay_signature,
            orderNumber: this.props.orderExtend.data.orderNumber,
            extendMonth: this.state.extendedMonth,
        };

        service.updatePaymentExtend(obj).then((response) => {

            if(response.data){
	    
	    this.setState({
                ...this.state,
                paymentConfirmation: response.data,
                isUpdated: true,
                updating : false
            }, () => {
                this.props.updateOrderTrue();
            });
	    }

        }, (error) => {
            console.log(error);
        });
    }   
 
    componentWillReceiveProps=()=>{
        this.setState({extendedMonth:'Select Period',updating : false});
    }
    componentDidMount=()=>{

        this.getRazorPayKeys();
    }
    
    render() {
        return (
            <Modal visible={this.props.orderExtend.showExtend} onClickBackdrop={this.props.closeOrderExtendModal }>
                <div className="modal-header">
                    <h5 className="modal-title">Extend Order</h5>
                </div>

                <div className="modal-body" >
                    <form>
                        <div className="row text-left">

                            {
                                this.state.isUpdated && (
                                    <div className="col-12">
                                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                                            Order extended successfully.
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            
                                        </div>
                                    </div>
                                    
                                )
                            }



                            <div className="col-12">
                                <div className="alert alert-secondary" role="alert">
                                    <ul>
                                        <li>Your order will get extended immediately once you confirm the duration. </li>
                                        <li>Any active deposit against the order will remain active till the new expiry date.</li>
                                        <li>You need to pay the rent of current month to extend this order.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-12 col-md-10 m-auto">
                                        <span>Order id : </span>
                                        <span className="text-black-50">{this.props.orderExtend.data.orderNumber}</span>
                                    </div>
                                    <div className="col-12 col-md-10 m-auto">
                                        <span >Placed on : </span>
                                        <span className="text-black-50">{new Date(this.props.orderExtend.data.orderDate).toDateString()}</span>
                                    </div>
                                    <div className="col-12 col-md-10 m-auto">
                                        <span>Current expiry date :</span>
                                        <span className="text-black-50"> {new Date(this.props.orderExtend.data.expirayDate).toDateString()}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6" style={{ align: 'right' }}>

                                <span className="text-left d-block">Select period to extend</span>
                                <select value={this.state.extendedMonth} onChange={this.onMonthSelect} className="browser-default custom-select mt-2" id="monthsExtend">
                                    {this.state.months.map(month => (
                                        <option key={this.state.months.indexOf(month)} value={month}>{month + ' Months'}</option>
                                    ))}
                                </select>

                            </div>

                        </div>

                    </form>
                </div>

                <div className="modal-footer">
                    <button disabled={this.state.updating} type="button" className="btn btn-success" onClick={() => {if(this.state.extendedMonth!='Select Period') {this.generateRazorPayExtendId(this.props.orderExtend.data.orderNumber)} }}>
                    {
                        this.state.updating?<div><span className="ml-2">Processing &nbsp;</span><span className="spinner-border spinner-border-sm"  role="status"></span></div>:"Pay Now"
                    }
                </button>
                    <button type="button" className="btn btn-primary" onClick={this.props.closeOrderExtendModal}>
                        Close
                </button>
                </div>
            </Modal>
        );
    }
}
const mapStateToProps = (currentState) => {
    return {
        orderExtend: currentState.orderExtend,
        updateOrderOnExtend: currentState.updateOrderOnExtend,
        userInfo: currentState.userState.userInfo,
    };
};


const mapActionsToProps = (dispatch) => {
    return {
        closeOrderExtendModal: () => {
            dispatch({ type: 'order.modal.extend.close' })
        },
        updateOrderTrue:()=>{
            dispatch({type: 'order.update.extend.true'})
        },
        updateOrderFalse:()=>{
            dispatch({type: 'order.update.extend.false'})
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(OrderExtendModal);