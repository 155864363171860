import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderComponent from './../../../Generic/Header/HeaderComponent';
import FooterComponent from './../../../Generic/Footer/FooterComponent';
import BreadCrumbs from './../../../Generic/BreadCrumbs/BreadCrumbs';
import H2Title from './../../../Generic/Element/Heading';
import * as service from './../../../../Service/service';

class ResetPassword extends Component {

    state = {
        password: {
            value: ''
        },
        confirm: {
            value: ''
        },
        isValid: false,
        showInfo: false,
        infoType: 'alert-warning',
        infoMessage: '',
        username: '',
        token: ''
    }

    constructor(props) {
        super(props);
        this.state = ({
            ...this.state,
            username: props.match.params.username,
            token: props.match.params.token
        });
    }

    updatePassword = () => {
        console.log(this.state);
        if (this.state.password.value != this.state.confirm.value) {
            this.setState({
                ...this.state,
                showInfo: true,
                infoMessage: "Passwords not matching. Kindly check and re-try."
            });
        } else if (this.state.password.value.length < 6) {
            this.setState({
                ...this.state,
                showInfo: true,
                infoMessage: "Passwords length must be at-least 6 characters."
            });
        } else {

            this.setState({
                ...this.state,
                showInfo: false
            });

            service.passwordReset({
                username: this.state.username,
                password: this.state.password.value,
                token: this.state.token
            }).then((response) => {

                if (response.data.indexOf("Success") != -1) {
                    console.log("Success");
                    this.setState({
                        ...this.state,
                        infoType: 'alert-success',
                        infoMessage: response.data.split(":")[1],
                        showInfo: true
                    });

                } else {

                    this.setState({
                        ...this.state,
                        infoType: 'alert-danger',
                        infoMessage: response.data.split(":")[1],
                        showInfo: true
                    });

                }
            }, (error) => {
                this.setState({
                    ...this.state,
                    infoType: 'alert-danger',
                    infoMessage: "Something went wrong. Please try again after sometime.",
                    showInfo: true
                });
            });
        }
    }

    onChangeHandler = (e, elem) => {
        let tmpState = JSON.parse(JSON.stringify(this.state));
        tmpState[elem].value = e.target.value;
        this.setState(tmpState);
    }

    showInfoMessage = () => {
        return this.state.showInfo ? "alert alert-dismissible fade show " + this.state.infoType : "alert alert-dismissible fade";
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.selectCityClose();
        this.props.updateBreadcumbs({
            navigation: [{ name: 'Home', link: './' }, { name: "Reset Password", link: '#' }],
            showSidebar: false,
            sidebar: []
        });
    }

    render() {
        return (
            <React.Fragment>
                <HeaderComponent />
                <BreadCrumbs />
                <main role="main" className="container mt-5">
                    <div className="row mt-5" style={{ height: '100%' }}>
                        <div className="col-5 col-md-5 d-none d-md-block" style={{ minHeight: '100%', backgroundPosition: '695px -409px', backgroundImage: 'url("https://images.unsplash.com/photo-1532103054090-3491f1a05d0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80")' }}>

                        </div>
                        <div className="col-12 col-md-7 p-5 mb-auto mt-auto position-relative" style={{ minHeight: '450px' }}>
                            <H2Title value="Change Password" />


                            {
                                this.state.showInfo && (
                                    <div className={this.showInfoMessage()} role="alert">
                                        <span>{this.state.infoMessage}</span>

                                        <button type="button" className="close" onClick={
                                            () => {
                                                this.setState({
                                                    ...this.state,
                                                    showInfo : false
                                                })
                                            }
                                        }>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                )
                            }

                            <div className="form-group mt-2">
                                <input type="password"
                                    value={this.state.password.value}
                                    onChange={(e) => this.onChangeHandler(e, 'password')}
                                    className="form-control" placeholder="New Password" />
                            </div>
                            <div className="form-group mt-2">
                                <input type="password"
                                    value={this.state.confirm.value}
                                    onChange={(e) => this.onChangeHandler(e, 'confirm')}
                                    className="form-control" placeholder="Confirm Password" />
                            </div>

                            {/* <button className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span className="ml-2">Update Password</span>
                            </button> */}

                            <button onClick={this.updatePassword} className="btn btn-primary" type="button" >Update Password</button>
                        </div>
                    </div>
                </main>

                <FooterComponent />
            </React.Fragment>
        );
    }
}

const mapActionsToProps = (dispatch) => {
    return {
        updateBreadcumbs: (data) => {
            dispatch({ type: 'breadcrumbs.update', data: data });
        },
        selectCityClose: () => {
            dispatch({ type: 'user.city.hide' });
        }
    };
}

export default connect(null, mapActionsToProps)(ResetPassword);