import React, { Component } from 'react';
import H2Title from './../../Generic/Element/Heading';
import MdDoneAll from 'react-ionicons/lib/MdDoneAll';

import KycElement from './KycElement';

import SelfieModal from './Selfie/SelfieModal';
import PanCardModal from './PanCard/PanCardModal';
import AddressProofModal from './AddressProof/AddressProofModal';
import JobIdModal from './JobId/JobIdModel'
import { connect } from 'react-redux';

import * as service from './../../../Service/service';
import BankStatementModel from './BankStatements/BankStatementModel';
import RentAgreementModal from './RentAgreement/RentAgreementModal';

class KycComponent extends Component {

    state = {
        kyc: [],
        isLoaded : false
    }

    getVerificationElement =  (status) => {

        let classes = {
            notUploaded: 'text-danger',
            notValidated: 'text-secondary',
            validated: 'text-success',
            cancelled: 'text-danger'
        }
        console.log("Status : "+ status)

        let selectedClass = classes.notUploaded;

        switch (status) {
            case 'NOT_UPLOADED': {
                selectedClass = classes.notUploaded
                break;
            }
            case 'PENDING': {
                selectedClass = classes.notValidated
                break;
            }
            case 'VERIFIED': {
                selectedClass = classes.validated
                break;
            }
            case 'REJECTED': {
                selectedClass = classes.cancelled
                break;
            }
            default: break;
        }

        selectedClass += "";
        return (<span className={selectedClass}>{status.split("_").join(" ")}</span>);
    }

    listDocuments = () => {
        service.listDocuments().then((response) => {
            this.setState({
                ...this.state,
                kyc: response.data,
                isLoaded : true
            });
        }, (error) => {
            console.log(error);
        })
    }

    deleteDocument = (documentId) => {
        service.deleteDocument(documentId).then((response) => {
            this.listDocuments();
        }, (error) => {
            console.log(error);
        });
    }

    componentDidMount() {
        this.listDocuments();
    }

    render() {
        return (
            <div className="row mt-3">
                <div className="col-12">
                    <H2Title value="User KYC" />
                    <span className="d-block font-weight-bold"> <MdDoneAll /> Complete KYC to get instant future deliveries.</span>

                    <div className="row mt-3">

                    <div className="col-12 col-md-4 mt-2" style={{ minHeight: '250px' }}>
                            <div className="row ml-1 mr-1 p-2 element-border" style={{ height: '100%' }}>
                                <div className="col-6">
                                    <span className="font-weight-bold">Aadhar Card</span>
                                </div>
                                <div className="col-6 text-right">
                                    {Object.keys(this.props.userInfo).length && this.getVerificationElement(this.props.userInfo.kycElement.addressProof)}
                                </div>
                                <div className="col-12 text-center mt-5 mb-3 cursor-pointer" onClick={this.props.showAddressProofModal}>
                                    <img alt="addressProof" height="72px" src="./svg/document.svg" />
                                    <span className="d-block mb-2 mt-1">Click to upload Documents</span>
                                </div>
                                <div className="col-12 mt-2 text-center">
                                    <span className="text-black-50" style={{ fontSize: '12px' }}>
                                        Verification of the document might take some time.
                                    </span>
                                </div>
                            </div>
                            <AddressProofModal />
                        </div>


                        <div className="col-12 col-md-4 mt-2" style={{ minHeight: '250px' }}>
                            <div className="row ml-1 mr-1 p-2 element-border" style={{ height: '100%' }}>
                                <div className="col-6">
                                    <span className="font-weight-bold">Selfie</span>
                                </div>
                                <div className="col-6 text-right">
                                    {Object.keys(this.props.userInfo).length && this.getVerificationElement(this.props.userInfo.kycElement.selfie)}
                                </div>
                                <div className="col-12 text-center mt-5 mb-3 cursor-pointer" onClick={this.props.showWebcamModal}>
                                    <img alt="WebCamModel" height="80px" src="./svg/selfie.svg" />
                                    <span className="d-block mb-2 mt-1">Click to take Selfie</span>
                                </div>
                                <div className="col-12 mt-2 text-center">
                                    <span className="text-black-50" style={{ fontSize: '12px' }}>
                                        Verification of the Selfie might take some time.
                                    </span>
                                </div>
                            </div>

                            <SelfieModal />
                        </div>

                        <div className="col-12 col-md-4 mt-2" style={{ minHeight: '250px' }}>
                            <div className="row ml-1 mr-1 p-2 element-border" style={{ height: '100%' }}>
                                <div className="col-6">
                                    <span className="font-weight-bold">PAN Card</span>
                                </div>
                                <div className="col-6 text-right">
                                    {Object.keys(this.props.userInfo).length && this.getVerificationElement(this.props.userInfo.kycElement.panCard)}
                                </div>
                                <div className="col-12 text-center mt-5 mb-3 cursor-pointer" onClick={this.props.showPanCardModal}>
                                    <img alt="PancardModel" height="80px" src="./svg/card.svg" />
                                    <span className="d-block mb-2">Upload PAN Card</span>
                                </div>
                                <div className="col-12 mt-2 text-center">
                                    <span className="text-black-50" style={{ fontSize: '12px' }}>
                                        Voorent may use PAN card to check Credit score.
                                    </span>
                                </div>
                            </div>

                            <PanCardModal />
                        </div>

                        <div className="col-12 col-md-4 mt-2" style={{ minHeight: '250px' }}>
                            <div className="row ml-1 mr-1 p-2 element-border" style={{ height: '100%' }}>
                                <div className="col-6">
                                    <span className="font-weight-bold">Job Id Card</span>
                                </div>
                                <div className="col-6 text-right">
                                    {Object.keys(this.props.userInfo).length && this.getVerificationElement(this.props.userInfo.kycElement.userJobId)}
                                </div>
                                <div className="col-12 text-center mt-5 mb-3 cursor-pointer" onClick={this.props.showJobIdModal}>
                                    <img alt="JobIDModel" height="80px" src="./svg/card.svg" />
                                    <span className="d-block mb-2">Upload Job Id Card</span>
                                </div>
                                <div className="col-12 mt-2 text-center">
                                    <span className="text-black-50" style={{ fontSize: '12px' }}>
                                        Verification of job id card might take some time
                                    </span>
                                </div>
                            </div>

                            <JobIdModal />
                        </div>

                        <div className="col-12 col-md-4 mt-2" style={{ minHeight: '250px' }}>
                            <div className="row ml-1 mr-1 p-2 element-border" style={{ height: '100%' }}>
                                <div className="col-6">
                                    <span className="font-weight-bold">Rent Agreement of Home</span>
                                </div>
                                <div className="col-6 text-right">
                                    {Object.keys(this.props.userInfo).length && this.getVerificationElement(this.props.userInfo.kycElement.rentAgreement)}
                                </div>
                                <div className="col-12 text-center mt-5 mb-3 cursor-pointer" onClick={this.props.showRentAgreementModal}>
                                    <img alt="RentAgreement" height="80px" src="./svg/card.svg" />
                                    <span className="d-block mb-2">Upload Rent Agreement of Home</span>
                                </div>
                                <div className="col-12 mt-2 text-center">
                                    <span className="text-black-50" style={{ fontSize: '12px' }}>
                                        Verification of rent agreement might take some time
                                    </span>
                                </div>
                            </div>

                            <RentAgreementModal />
                        </div>

                        <div className="col-12 col-md-4 mt-2" style={{ minHeight: '250px' }}>
                            <div className="row ml-1 mr-1 p-2 element-border" style={{ height: '100%' }}>
                                <div className="col-6">
                                    <span className="font-weight-bold">3 Months Bank Statement</span>
                                </div>
                                <div className="col-6 text-right">
                                    {Object.keys(this.props.userInfo).length && this.getVerificationElement(this.props.userInfo.kycElement.bankStatement)}
                                </div>
                                <div className="col-12 text-center mt-5 mb-3 cursor-pointer" onClick={this.props.showBankStatementModal}>
                                    <img alt="BankStatement" height="80px" src="./svg/card.svg" />
                                    <span className="d-block mb-2">Upload 3 Months Bank Statement</span>
                                </div>
                                <div className="col-12 mt-2 text-center">
                                    <span className="text-black-50" style={{ fontSize: '12px' }}>
                                        Verification of bank statemenets might take some time
                                    </span>
                                </div>
                            </div>

                            <BankStatementModel />
                        </div>
                    </div>

                    <div className="row element-border mt-3 mr-1 ml-1 mb-3">

                        {
                            this.state.isLoaded && this.state.kyc.length == 0 && (
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 text-center mt-2">
                                            <h5 className="mt-2 mb-4 text-secondary">KYC - Corporate Email id verification by mailing on support@voorent.com</h5>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className="row element-border mt-3 mr-1 ml-1 mb-3">

                        {
                            !this.state.isLoaded && (<div className="col-12 m-auto">
                                <div className="row">
                                    <div className="col-12 text-center mt-5">
                                        <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        <span className="pl-3 text-black-50 d-block" >Checking backend . . </span>
                                    </div>
                                </div>
                            </div>)
                        }


                        {
                            this.state.isLoaded && this.state.kyc.length == 0 && (
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 text-center mt-5">
                                            <img height="150px" src="./svg/documents-all.svg" />
                                            <h5 className="mt-3 mb-5">No documents uploaded, Your uploaded documents will be shown here.</h5>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            this.state.kyc.map((item, iIndex) => (
                                <React.Fragment key={iIndex}>
                                    <KycElement deleteDocument={this.deleteDocument} kycItem={item} />
                                    {iIndex == this.state.kyc.length - 1 ? '' : (<hr className="mt-1 mb-1" />)}
                                </React.Fragment>
                            ))
                        }



                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (currentState) => {
    return {
        kyc: currentState.kyc,
        userInfo: currentState.userState.userInfo
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        showWebcamModal: () => {
            dispatch({ type: 'kyc.webcam.show' });
        },
        showPanCardModal: () => {
            dispatch({ type: 'kyc.pancard.show' });
        },
        showAddressProofModal: () => {
            dispatch({ type: 'kyc.addressProof.show' });
        },
        showJobIdModal: () => {
            dispatch({ type: 'kyc.jobId.show' });
        },
        showBankStatementModal: () => {
            dispatch({ type: 'kyc.bankStatement.show' });
        },
        showRentAgreementModal: () => {
            dispatch({ type: 'kyc.rentAgreement.show' });
        }
        
    };
}

export default connect(mapStateToProps, mapActionsToProps)(KycComponent);