import React, { Component } from 'react';

import DepositItem from './DepositItem';

class DepositMonthly extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12 mb-3 font-weight-bold mt-4">
                     <span>{this.props.month}</span>
                    </div>

                    {
                        this.props.deposit.map((depositItem, diIndex) => (
                            <React.Fragment key={diIndex}>
                                <DepositItem item={depositItem} />
                                { diIndex == this.props.deposit.length -1 ? '' : (<hr className="mt-3 mb-3" />) }
                            </React.Fragment>
                        ))
                    }
                    

                    

                </div>
            </React.Fragment>
        );
    }
}

export default DepositMonthly;