import React from 'react';
import  _  from 'lodash';
import PropTypes from 'prop-types'; 

const Pagination = props => {

    const { itemsCount,pageSize,onPageChange ,currentPage} =props;
    console.log(currentPage);
    const pagesCount = Math.ceil(itemsCount / pageSize); //Math.ceil convert to integer
    if(pagesCount===1) return null;
    const pages = _.range(1, pagesCount + 1);

    return(
    <nav>
        <ul className="pagination" style={{cursor:'pointer'}}>
        <li key={0} className="page-item">
            <a  className="page-link" onClick={() => onPageChange(1)}>First</a>
        </li>    
        <li key={1} className="page-item">
            <a  className="page-link" onClick={() => (currentPage-1)>=1?onPageChange(currentPage-1):onPageChange(currentPage)}>Prev</a>
        </li>
        <li key={2} className='page-item active'> 
            <a  className="page-link" onClick={() => onPageChange(currentPage)}>{currentPage}</a>
        </li>

        <li key={3} className="page-item">
            <a  className="page-link" onClick={() => onPageChange(((currentPage+1)<=pagesCount)?currentPage+1:currentPage)}>Next</a>
        </li>
        <li key={4} className="page-item">
            <a  className="page-link" onClick={() => onPageChange(pagesCount)}>Last</a>
        </li>
        </ul>
    </nav>
    );
};

Pagination.propTypes = {
    itemsCount:PropTypes.number.isRequired,
    pageSize:PropTypes.number.isRequired,
    onPageChange:PropTypes.func.isRequired ,
    currentPage:PropTypes.number.isRequired
};
 
export default Pagination;