import React, { Component } from 'react';

import Rating from './Rating';

import * as endpoints from './../../../Service/endpoints';

class SingleProduct extends Component {

    style = {
        borderStyle: {
            border: "1px solid hsla(240,2%,88%,.74)",
            borderRadius : '8px'
        },
        productBorder: {
            display: 'inline-block',
            position: 'relative',
            top: '-5px'
        }
    }


    getProductUrl = (product) => {

        if (typeof product.category != "undefined") {
            return endpoints.getRoute(`${product.category.slug}/${product.subCategory.slug}/${product.slug}`);
        } else {
            return "#";
        }

    }



    render() {

        return (
            <div className={'col-12 col-md-4 mb-3 col-'+(this.props.length==4?'xl-3':'lg-4') + this.props.styleValue.padding}>
                <div style={this.style.borderStyle}>
                    <div className="row m-0 ph-item">
                        <div className="col-12 p-0">
                            <img style={{ width: 'inherit', maxHeight: '220px' }} src={this.props.product.featuredImage} alt={this.props.product.slug} />
                            {this.props.product.status==='Out of Stock' && (<span style={{
                                position: 'absolute',
                                content: 'OUT OF STOCK',
                                background:'white',
                                right: '-10px',
                                color: 'red',
                                transform: 'rotate(40deg)',
                                top: '20px',}}>Out Of Stock</span>)}
                        </div>
                    </div>

                    <div className="row m-1">
                        <div className="col-12 pb-3">
                            <a href={this.getProductUrl(this.props.product)} type="button" >
                                <span className="font-weight-bold">{this.props.product.name}</span>
                            </a>

                            <div className="mt-1">
                                {
                                    this.props.isMedical!=true && (
                                        <div>
                                            <span className="text-black-50">Starting from :</span>
                                            <span>₹ {parseFloat(this.props.product.startingFrom/1.18).toFixed(0)}/month</span>
                                        </div>
                                    )
                                }
                                <span className="d-block">
                                    <span className="text-black-50">Min. Tenure : </span>
                                    <span className="ml-2">{this.props.minTenure}</span>
                                </span>
                            </div>

                            <div className="text-right">
                                <a href={this.getProductUrl(this.props.product)} type="button" className="btn btn-sm btn-outline-primary mt-2"> View Product </a>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default SingleProduct;