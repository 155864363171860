import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import { connect } from 'react-redux';
import "./../../../../styles/modal-styles.css";

class ServiceAreaSelect extends Component {

    state = {
        selectCity: false
    }

    selectCity = (cityName, cityId) => {

        localStorage.setItem("city", cityName);
        localStorage.setItem("cityId", cityId);
        this.props.selectCityClose();

        var path = window.location.pathname;
        var pathArray = path.split("/");
        pathArray.splice(0, 2);
        var finalPath = pathArray.join("/");
        window.location.href = (`${window.location.origin}/${localStorage.city}/${finalPath}`)

    }

    closeModal = () => {
        //this.setState({selectCity : false});
        this.props.selectCityClose();
    }

    componentDidMount() {
        if (typeof localStorage.city == "undefined" || window.location.pathname == "/noida" || window.location.pathname == "/noida/") {
            this.props.selectCityOpen();
        } else {
            if (window.location.pathname == "/") {
                window.location.href = `/${localStorage.city}`;
            }
        }
    }


    render() {
        return (
            <Modal visible={this.props.selectCity.show} onClickBackdrop={this.closeModal}>
                <div className="modal-header m-auto text-capitalize">
                    <h5 className="modal-title">We are currently available in</h5>
                </div>
                <div className="modal-body">
                    <div className="row p-2">
                        
                        <div onClick={(e) => this.selectCity('gurgaon', 1)} className="col-6 col-md-6 text-center select-city">
                            <span className="city gurgoan m-auto"></span>
                            <span>Gurgaon</span>
                        </div>
                        <div onClick={(e) => this.selectCity('delhi', 2)} className="col-6 col-md-6 text-center select-city">
                            <span className="city delhi m-auto"></span>
                            <span>Delhi</span>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (currentState) => {
    return {
        selectCity: currentState.selectCity,
    };
};

const mapActionsToProps = (dispatch) => {
    return {
        selectCityOpen: () => {
            dispatch({ type: 'user.city.show' });
        },
        selectCityClose: () => {
            dispatch({ type: 'user.city.hide' });
        }
    };
}

export default connect(mapStateToProps, mapActionsToProps)(ServiceAreaSelect);