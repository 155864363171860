import React, { Component } from 'react';

class FailureComponent extends Component {
    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-12 text-center">
                    <img height="128px" width="128px" src="./../svg/order-failure.svg" alt=""/>
                    </div>
                    <div className="col-12 text-center mt-2">
                        <h3 className="text-uppercase">Sorry ! We are unable to process your payment.</h3>
                        <h6>Your order is placed but, its still pending.</h6>
                        <h6>You can re-try payment for the order from your Account > Orders.</h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default FailureComponent;