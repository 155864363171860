import React, { Component } from 'react';

class Blog6 extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="col-12">
                    <span>Life is a perpetual journey of learning and self-improvement. Whether its to boost your fitness and health, productivity, or overall happiness. Her’s a blog which can help you out I upgrading your lifestyle.</span><br /><br></br>
                    <h4>Morning Routine:</h4><div>Start your day with something productive. You can start with exercising to kickstart your day. A good morning routine can result in increasing productivity throughout the day. Not only productivity, working out in morning will make you feel fresh and energetic throughout the day.</div><br />
                    <center><img
                        style={{ maxHeight: "700px", maxWidth: "400px" }}
                        className="d-block w-100 lazyload "
                        src="https://voorent.com/voorent-storage/Blog6.1.jpg"
                        >
                    </img></center><br></br>
                    <h4>Health:</h4><div>As it is said that “health is wealth” we should always prioritize our health over everything. Ensure you are getting enough sleep, staying hydrated, having a balanced meal which contains a good amount of macro and micro nutrients, contributing to mental and physical well-being. Always go for a diet plan where you can easily hit all the micro and macro nutrients, for fulfilling micro nutrients add some fruits in your meals and have a protein rich diet.</div><br />
                    <center><img
                       style={{ maxHeight: "700px", maxWidth: "400px" }}
                        className="d-block w-100 lazyload "
                        src="https://voorent.com/voorent-storage/Blog6.2.jpg"
                        >
                    </img></center><br></br>
                    <h4>Keep Learning:</h4><div>Learning is a perpetual process of life to upgrade your lifestyle starts focusing on growing your knowledge regularly whether it’s reading a new book, learning a new language, or exploring new subjects. This will also help you to boost your confidence. Learning is all about building a new version of yourself and improving your personality.</div><br />
                    <center><img
                       style={{ maxHeight: "700px", maxWidth: "400px" }}
                        className="d-block w-100 lazyload "
                        src="https://voorent.com/voorent-storage/Blog6.3.jpg"
                        >
                    </img></center><br></br>
                    <h4>Sort & Organise:</h4><div>An organized and tidy space leads to a clutter-free mind. Make time organize your room and remove all the unnecessary objects from the room to simplify your space which can foster creativity as well as productivity.</div><br />
                    <center><img
                        style={{ maxHeight: "700px", maxWidth: "400px" }}
                        className="d-block w-100 lazyload "
                        src="https://voorent.com/voorent-storage/Blog6.4.jpg"
                        >
                    </img></center><br></br>
                    <h4>Quality over quanity:</h4><div>Always try to invest in quality items and experience rather than accumulating possesions. This will remove unnecessary stress on storing and maintaining large quantity of items.</div><br />
                    {/* <img
                        style={{ minHeight: "100px" }}
                        className="d-block w-100 lazyload "
                        src="https://voorent.com/voorent-storage/Blog6.3.jpg"
                        >
                    </img> */}
                    <h4>Practice gratitude:</h4><div>Cultivate an attitude of gratitude. Always try to appreciate small things in life. Keeping a gratitude journal can help focus on the positive aspects of life, fostering a sense of contentment.</div><br />
                    <center><img
                        style={{ maxHeight: "700px", maxWidth: "400px" }}
                        className="d-block w-100 lazyload "
                        src="https://voorent.com/voorent-storage/Blog6.5.jpg"
                        >
                    </img></center><br></br>
                    <h4>Time Management:</h4><div>Time is the most valuable thing in the world as I can not be purchased. You can easily increase prodctivity by efficiently managing your time by prioritizing tasks and setting realistic goals whether long term or short term. You can also use tools to organize your schedule and always ensure a proper balance between work, leisure and personal growth.</div><br />
                    <center><img
                        style={{ maxHeight: "700px", maxWidth: "400px" }}
                        className="d-block w-100 lazyload "
                        src="https://voorent.com/voorent-storage/Blog6.6.jpg"
                        >
                    </img><br></br>
                    <h4>Disconnect with social media:</h4><div>Take a break from screens, avoid using social media to connect with the present world. Try t engage yourself in hobbies, do things you like the most and try to connect with the real world instead of the world present on the social media platforms. By avaoiding soial media one can keep themselves safe from getting addictive to social media.</div><br />
                    <center></center><img
                        style={{ maxHeight: "700px", maxWidth: "400px" }}
                        className="d-block w-100 lazyload "
                        src="https://voorent.com/voorent-storage/Blog6.7.jpg"
                        >
                    </img></center><br></br>
                    <h4>Nurture relationships:</h4><div>Nurture healthy relationship. Connect with friends, family as they can be the strong support system which is essential for emotional well-being.</div><br />
                    {/* <img
                        style={{ minHeight: "100px" }}
                        className="d-block w-100 lazyload "
                        src="https://voorent.com/voorent-storage/Blog6.7.jpg"
                        >
                    </img> */}
                    <span>For more blogs visit: <a href="https://voorent.com"><u>https://voorent.com</u></a></span><br></br>
                    <span>If you are looking for furniture but don’t have that much budget to buy it, Voorent brings you the solution by providing furniture and appliances on rent which can add some positive vibe to your space. <br></br><br></br>Visit: <a href="https://voorent.com"><u>https://voorent.com</u></a></span><br></br>
                </div>
            </React.Fragment>
        );
    }
}

export default Blog6;